/**
 * A machine for loading, updating, and creating business objects of an entity
 * @typedef SweftDataMachineEntityDataActorMachine
 * @type {StateMachine<SweftDataUnknownDataActorMachineContext, none, SweftDataUnknownDataActorMachineTypestate>}
 */

import { VanguardService } from "@app/services/vanguardService";
import { SequencesService } from "@app/services/sequencesService";
import { generateOptimisticDataMachine } from "@app/data/machine/actors/optimisticDataActorMachine";

/**
 * @interface SweftDataMachineEntityDataActorMachineContext
 * @extends SweftOptimisticDataMachineContext
 * @prop {"id"} keyProperty
 */

/**
 * @param entity
 * @param type
 * @returns {SweftOptimisticDataMachineGeneratorProps}
 */
export const generateEntityDataMachineGeneratorProps = ({ entity, relatedAttributePathMap = {}, type = "BUSINESS_OBJECTS", schemaTreeExclusionList, relatedProjectionPaths, projectionAttributeList, loadOnSpawn, noParent }) => {
    const relatedEntityPropertyMap = Object.keys(relatedAttributePathMap).reduce((entityToPathListMap, nextPath) => {
        const nextEntity = relatedAttributePathMap[nextPath];
        if (entityToPathListMap[nextEntity]) {
            return {
                ...entityToPathListMap,
                [nextEntity]: [...entityToPathListMap[nextEntity], nextPath]
            };
        }
        return {
            ...entityToPathListMap,
            [nextEntity]: [nextPath]
        };
    }, {});
    const keyProperty = "id";
    return {
        loadOnSpawn,
        entity,
        type,
        keyProperty,
        noParent,
        relatedEntityPropertyMap,
        projectionAttributeList,
        createMachineOptions: {
            services: {
                createService: async (context) => {
                    const { object } = context;
                    return new Promise((resolve, reject) => {
                        VanguardService.data.createBusinessObject({ businessObject: object })
                            .then(() => {
                                resolve({
                                    newObject: {
                                        ...object,
                                    }
                                });
                            });
                    });
                },
                generatedIdService: async (context) => {
                    const { gidAttributesList, object } = context;
                    /**
                     * Returns the object with the generated id properties populated with the next sequence number.
                     */
                    const generatedIdAttributeList = await Promise.all(gidAttributesList.map((attribute) => {
                        return new Promise((resolve, reject) => {
                            SequencesService.data.generatedIdService({ attribute })
                                .then((sequenceNumber) => {
                                    resolve({
                                        key: attribute.name,
                                        value: sequenceNumber
                                    });
                                });
                        });
                    }));

                    const generatedIdObject = generatedIdAttributeList.reduce((currentObjVal, nextGeneratedIdObj) => {
                        return {
                            ...currentObjVal,
                            [nextGeneratedIdObj.key]: nextGeneratedIdObj.value
                        };
                    }, {});

                    return {
                        newObject: {
                            ...object,
                            ...generatedIdObject
                        }
                    };
                }
            }
        },
        updateMachineOptions: {
            services: {
                updateService: async (context) => {
                    const { object, fieldBeingUpdated } = context;
                    return new Promise((resolve, reject) => {
                        VanguardService.data.updateBusinessObject({ businessObject: object, fieldBeingUpdated, keyProperty })
                            .then(() => {
                                resolve({
                                    newObject: {
                                        ...context
                                    }
                                });
                            });
                    });
                }
            }
        },
        loadMachineOptions: {
            services: {
                loadService: async (context, event) => {
                    const { projectionAttributeList: projectionAttributeListFromEvent } = context;
                    return new Promise((resolve, reject) => {
                        VanguardService.data.getBusinessObjectsOfEntity({ entity, schemaTreeExclusionList, relatedProjectionPaths, projectionAttributeList: projectionAttributeListFromEvent ?? [] }).then((newObjs) => {
                            resolve({
                                loadedData: newObjs
                            });
                        }).catch(() => reject());
                    });
                },
            }
        },
        services: {
            loadService: async (context, event) => {
                const { projectionAttributeList: projectionAttributeListFromEvent } = context;
                return new Promise((resolve, reject) => {
                    VanguardService.data.getBusinessObjectsOfEntity({ entity, schemaTreeExclusionList, relatedProjectionPaths, projectionAttributeList: projectionAttributeListFromEvent ?? [] }).then((newObjs) => {
                        resolve({
                            loadedData: newObjs
                        });
                    }).catch(() => reject());
                });
            },
            loadDataFilteredForTransientData: async (context, event) => {
                const { transientData, pollingProjectionAttributeList } = context;

                const transientObjectIdList = transientData.map((transientObj) => transientObj?.object?.id);
                if (transientObjectIdList.length === 0) {
                    return {
                        loadedData: []
                    };
                }
                const additionalJsonLogicQueryObject = { "and": [{ "in": [{ "var": "id" }, transientObjectIdList] }] };
                return new Promise((resolve, reject) => {
                    VanguardService.data.getBusinessObjectsOfEntity({ entity, schemaTreeExclusionList, additionalJsonLogicQueryObject, projectionAttributeList: pollingProjectionAttributeList }).then((newObjs) => {
                        resolve({
                            loadedData: newObjs
                        });
                    }).catch((error) => {
                        console.log(error);
                        reject();
                    });
                });
            },
        }
    };
};

/**
 * @type {SweftDataMachineEntityDataActorMachine}
 */
export const generateEntityDataActorMachine = ({ loadOnSpawn, entity, type = "BUSINESS_OBJECTS", relatedAttributePathMap, schemaTreeExclusionList, relatedProjectionPaths, projectionAttributeList, noParent }) => generateOptimisticDataMachine(generateEntityDataMachineGeneratorProps({ loadOnSpawn, entity, type, schemaTreeExclusionList, relatedAttributePathMap, relatedProjectionPaths, projectionAttributeList, noParent }));
