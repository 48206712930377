import React from "react";
import { DashboardModule } from "@app/dashboard/controls/DashboardModule";
import { SkeletonAlertsList } from "@app/dashboard/controls/DashboardModuleAlerts/SkeletonAlertsList";
import { DashboardModuleAlertTile } from "@app/dashboard/controls/DashboardModuleAlerts/DashboardModuleAlertTile";
import { DashboardModuleSpecialtyToggle } from "@app/dashboard/controls/DashboardModuleAlerts/DashboardModuleSpecialtyToggle";
import { DashboardModuleAlertTileList } from "@app/dashboard/controls/DashboardModuleAlerts/DashboardModuleAlertTileList";

export const DashboardModuleAlertsComponent = ({ useAlerts, useAlertStatus, useSpecialty } = {}) => {
    const { alerts } = useAlerts();
    return <DashboardModule className="col-start-1 col-end-6 row-span-6">
        <DashboardModule.Header>
            <DashboardModule.Title>Priority Alerts</DashboardModule.Title>
            <div>
                <DashboardModuleSpecialtyToggle useSpecialty={useSpecialty}/>
            </div>
        </DashboardModule.Header>
        <DashboardModule.Body>
            {alerts === null ?
                <SkeletonAlertsList/> :
                <DashboardModuleAlertTileList>
                    {alerts?.map(
                        (alert, index) => (
                            <DashboardModuleAlertTile key={alert?.path ?? index} alert={alert} useAlertStatus={useAlertStatus}/>
                        )
                    )}
                </DashboardModuleAlertTileList>
            }

        </DashboardModule.Body>
    </DashboardModule>;
};
