import React from "react";
import { createContext, useContext, useMemo } from "react";
import { useInterpret } from "@xstate/react";
import { configDataMachine } from "@app/data/config/machine";

export const ConfigDataContext = createContext({});

export const useConfigData = () => useContext(ConfigDataContext);

export const ConfigDataWrapper = ({ children }) => {
    const configDataMachineService = useInterpret(configDataMachine, { devTools: true });
    const { send: sendToConfigDataMachineService } = configDataMachineService;
    const contextValue = useMemo(() => ({
        configDataMachineService,
        sendToConfigDataMachineService
    }), [configDataMachineService, sendToConfigDataMachineService]);

    return (
        <ConfigDataContext.Provider value={contextValue}>{children}</ConfigDataContext.Provider>
    );
};
