/**
 * Machine used to spawn data actors used throughout the app.
 * @typedef SweftDataMachine
 * @type {StateMachine<SweftDataMachineContext, SweftDataMachineEvent>}
 */

/**
 * @interface SweftDataMachineDataActor
 * @prop {string} type
 * @prop {ActorRefFrom<T>} ref
 */

/**
 * @interface SweftDataMachineUnknownDataActor
 * @extends {SweftDataMachineDataActor}
 * @prop {'unknownDataActor'} type
 * @prop {ActorRefFrom<SweftDataUnknownDataActorMachine>} ref
 */

/**
 * @interface SweftDataMachineEntitySchemaDataActor
 */

/**
 * @interface SweftDataMachineEntitySchemaDataActor
 * @extends {SweftDataMachineDataActor}
 * @prop {'entitySchemaDataActor'} type
 * @prop {ActorRefFrom<SweftDataMachineEntitySchemaDataActorMachine>} ref
 */

/**
 * @interface SweftDataMachineAttributeSchemaDataActor
 * @extends {SweftDataMachineDataActor}
 * @prop {'attributeSchemaDataActor'} type
 * @prop {ActorRefFrom<SweftDataMachineAttributeSchemaDataActorMachine>} ref
 */

/**
 * @interface SweftDataMachineEntityDataActor
 * @extends {SweftDataMachineDataActor}
 * @prop {"entity"} type
 * @prop {string} entity
 * @prop {ActorRefFrom<SweftDataMachineEntitySchemaDataActorMachine>} ref
 */

/**
 * @interface SweftDataMachineContext
 * @prop {null |SweftDataMachineUnknownDataActor} unknownDataActor
 * @prop {null | SweftDataMachineEntitySchemaDataActor} entitySchemaDataActor
 * @prop {Array<SweftDataMachineEntityDataActor>} entityDataActorList
 */

/**
 * @typedef SweftDataMachineEvent
 * @type {{ type: 'COMMAND_SPAWN_ENTITY_DATA_ACTOR'; entity: string }}
 */

import { assign, createMachine, spawn, actions } from "xstate";

const { send, pure } = actions;
import { unknownDataActorMachine } from "@app/data/machine/actors/unknownDataActorMachine";
import {
    generateEntityDataActorMachine
} from "@app/data/machine/actors/entityDataActorMachine";
import {
    findDataActorObjForEntityFromContext,
    findDataActorObjForEntitySavedQueryCountsFromContext,
    findDataActorObjForEntitySavedQueryCountsWithAdditionalJsonLogicObjectFromContext,
    findDataActorObjForEntitySavedQueryFromContext
} from "@app/data/machine/utils";
import {
    generateEntitySavedQueriesCountsMachine
} from "@app/data/machine/actors/entitySavedQueriesCountsDataActor";
import { generateEntitySavedQueryDataActorMachine } from "@app/data/machine/actors/entitySavedQueryDataActorMachine";


/**
 * @type {SweftDataMachine}
 */
export const dataMachine = createMachine({
    id: "dataMachine",
    context: {
        unknownDataActor: null,
        entitySavedQueryCountsDataActorList: [],
        entitySavedQueryDataActorList: [],
        entityDataActorList: []
    },
    initial: "init",
    states: {
        init: {
            entry: "spawnInitialActors",
            always: "listening",
        },
        listening: {
            on: {
                COMMAND_SPAWN_ENTITY_DATA_ACTOR: [
                    {
                        actions: "spawnEntityDataActor",
                        cond: "noDataActorForEntity"
                    },
                    {
                        actions: "sendCommandLoadDataToEntityDataActor"
                    }
                ],
                COMMAND_SPAWN_ENTITY_SAVED_QUERY_COUNTS_DATA_ACTOR: [
                    {
                        actions: "spawnEntitySavedQueryCountsDataActor",
                        cond: "noDataActorForEntitySavedQueryCounts"
                    },
                    {
                        actions: "sendCommandLoadDataToEntitySavedQueryCountsDataActor"
                    }
                ],
                COMMAND_SPAWN_ENTITY_SAVED_QUERY_DATA_ACTOR: [
                    {
                        actions: "spawnEntitySavedQueryDataActor",
                        cond: "noDataActorForEntitySavedQuery"
                    },
                    {
                        actions: "sendCommandLoadDataToEntitySavedQueryDataActor"
                    }
                ],
                RELATED_TRANSIENT_BOBJ_CREATED: {
                    actions: pure((context, event) => {
                        return Object.keys(context.entityDataActorList).map((actorKey) => {
                            return send(event, { to: context.entityDataActorList[actorKey].ref });
                        });
                    }),
                },
                RELATED_TRANSIENT_BOBJ_DELETED: {
                    actions: pure((context, event) => {
                        return Object.keys(context.entityDataActorList).map((actorKey) => {
                            return send(event, { to: context.entityDataActorList[actorKey].ref });
                        });
                    }),
                }
            }
        }
    }
},
{
    actions: {
        spawnInitialActors: assign({
            unknownDataActor: () => ({
                type: "unknownDataActor",
                ref: spawn(unknownDataActorMachine)
            })
        }),
        spawnEntityDataActor: assign({
            entityDataActorList: (context, event) => {
                const { entity, relatedAttributePathMap, schemaTreeExclusionList, relatedProjectionPaths, projectionAttributeList, loadOnSpawn = true } = event;
                return context.entityDataActorList.concat({
                    type: "entity",
                    entity,
                    ref: spawn(generateEntityDataActorMachine({ loadOnSpawn, entity, type: `${entity}_entityDataActor`, relatedAttributePathMap, schemaTreeExclusionList, relatedProjectionPaths, projectionAttributeList }))
                });
            }
        }),
        spawnEntitySavedQueryCountsDataActor: assign({
            entitySavedQueryCountsDataActorList: (context, event) => {
                const { entity, savedQueryIdList, additionalJsonLogicQueryObject } = event;
                return context.entitySavedQueryCountsDataActorList.concat({
                    type: "entitySavedQueryCounts",
                    entity,
                    additionalJsonLogicQueryObject,
                    ref: spawn(generateEntitySavedQueriesCountsMachine({
                        entity,
                        savedQueryIdList,
                        additionalJsonLogicQueryObject
                    }))
                });
            }
        }),
        spawnEntitySavedQueryDataActor: assign({
            entitySavedQueryDataActorList: (context, event) => {
                const { entity, savedQuery, additionalJsonLogicQueryObject } = event;
                return context.entitySavedQueryDataActorList.concat({
                    type: "entitySavedQuery",
                    entity,
                    savedQuery,
                    additionalJsonLogicQueryObject,
                    ref: spawn(generateEntitySavedQueryDataActorMachine({ entity, savedQuery, additionalJsonLogicQueryObject, type: `${entity}_savedQuery_${savedQuery.id}_dataActor` }))
                });
            }
        }),
        sendCommandLoadDataToEntitySavedQueryCountsDataActor: pure((context, event) => {
            const { entity, additionalJsonLogicQueryObject } = event;
            let dataActorForEntitySavedQueryCounts;
            if (additionalJsonLogicQueryObject) {
                dataActorForEntitySavedQueryCounts = findDataActorObjForEntitySavedQueryCountsWithAdditionalJsonLogicObjectFromContext({ context, entity, additionalJsonLogicQueryObject });
            } else {
                dataActorForEntitySavedQueryCounts = findDataActorObjForEntitySavedQueryCountsFromContext({
                    context,
                    entity
                });
            }
            return send({ type: "START_POLLING" }, { to: dataActorForEntitySavedQueryCounts.ref });
        }),
        notifyExistingEntitySavedQueryCountsActorsOfContextChanges: pure((context, event) => {
            const { entitySavedQueryCountsDataActorList } = context;
            const { alertListByEntityMap, savedQueryIdByAlertIdMap } = event;
            const alertEntityList = Object.keys(alertListByEntityMap);

            return alertEntityList.reduce((eventsToSendList, nextAlertEntity) => {
                const existingEntitySavedQueryCountsDataActorObj = entitySavedQueryCountsDataActorList.find((object) => object?.entity === nextAlertEntity);

                if (!existingEntitySavedQueryCountsDataActorObj) {
                    return eventsToSendList;
                }
                return [
                    ...eventsToSendList,
                    send({ type: "EXTERNAL_CONTEXT_CHANGES", alertListByEntityMap, savedQueryIdByAlertIdMap }, { to: existingEntitySavedQueryCountsDataActorObj.ref })
                ];
            }, []);
        }),
        sendCommandLoadDataToEntityDataActor: pure((context, event) => {
            const { entity } = event;
            const dataActorForEntity = findDataActorObjForEntityFromContext({ context, entity });
            return send({ type: "LOAD_DATA" }, { to: dataActorForEntity.ref });
        }),
        sendCommandLoadDataToEntitySavedQueryDataActor: pure((context, event) => {
            const { entity, savedQueryId } = event;
            const dataActorForEntitySavedQuery = findDataActorObjForEntitySavedQueryFromContext({ context, entity, savedQueryId });
            return send({ type: "LOAD_DATA" }, { to: dataActorForEntitySavedQuery.ref });
        })
    },
    guards: {
        noDataActorForEntity: (context, event) => {
            const { entity } = event;
            const dataActorForEntity = findDataActorObjForEntityFromContext({ context, entity });
            return !!dataActorForEntity === false;
        },
        noDataActorForEntitySavedQueryCounts: (context, event) => {
            const { entity, additionalJsonLogicQueryObject } = event;
            let existingEntitySavedQueryCountsDataActorObj;
            if (additionalJsonLogicQueryObject) {
                existingEntitySavedQueryCountsDataActorObj = findDataActorObjForEntitySavedQueryCountsWithAdditionalJsonLogicObjectFromContext({ context, entity, additionalJsonLogicQueryObject });
            } else {
                existingEntitySavedQueryCountsDataActorObj = findDataActorObjForEntitySavedQueryCountsFromContext({
                    context,
                    entity
                });
            }
            return !!existingEntitySavedQueryCountsDataActorObj === false;
        },
        noDataActorForEntitySavedQuery: (context, event) => {
            const { entity, savedQuery } = event;
            const dataActorForEntitySavedQuery = findDataActorObjForEntitySavedQueryFromContext({ context, entity, savedQuery });
            return !!dataActorForEntitySavedQuery === false;
        }
    }
});
