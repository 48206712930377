import { generateEntityDataMachineGeneratorProps } from "@app/data/machine/actors/entityDataActorMachine";
import { VanguardService } from "@app/services/vanguardService";
import { generateOptimisticDataMachine } from "@app/data/machine/actors/optimisticDataActorMachine";
import { applyAdvancedFilters } from "@app/grid/advancedFilters/advancedFilters";

const generateEntitySavedQueryDataMachineGeneratorProps = ({ entity, savedQuery, additionalJsonLogicQueryObject, type = "ENTITY_SAVED_QUERY_BUSINESS_OBJECTS" }) => {
    const entityDataMachineProps = generateEntityDataMachineGeneratorProps({ entity });

    return {
        ...entityDataMachineProps,
        type,
        services: {
            loadService: async (context, event) => {
                return new Promise((resolve, reject) => {
                    VanguardService.data.getBusinessObjectsOfEntitySavedQuery({ entity, savedQuery, additionalJsonLogicQueryObject }).then((newObjs) => {
                        resolve({
                            loadedData: newObjs
                        });
                    });
                });
            },
            loadDataFilteredForTransientData: async (context, event) => {
                return new Promise((resolve, reject) => {
                    VanguardService.data.getBusinessObjectsOfEntitySavedQuery({ entity, savedQuery, additionalJsonLogicQueryObject }).then((newObjs) => {
                        resolve({
                            loadedData: newObjs
                        });
                    });
                });
            },
        },
        guardsOptions: {
            shouldAddObjectToTransientDataCallback: ({ object }) => {
                try {
                    const passingObjectList = applyAdvancedFilters({ logic: savedQuery.savedQueryObject, rows: [object] });
                    console.log({ passingObjectList });
                    return passingObjectList.length !== 0;
                } catch (e) {
                    return true;
                }
            }
        }
    };
};

export const generateEntitySavedQueryDataActorMachine = ({ entity, savedQuery, type = "ENTITY_SAVED_QUERY_BUSINESS_OBJECTS" }) => generateOptimisticDataMachine(generateEntitySavedQueryDataMachineGeneratorProps({ entity, savedQuery, type }));
