// @ts-check
import { findDataActorObjForEntityFromContext, findDataActorObjForEntitySavedQueryFromContext } from "@app/data/machine/utils";

/**
 * Generates and returns state selector function for getting the entity data actor of the provided entity
 * @prop {string} entity
 * @prop {Record<unknown, unknown>} config
 * @returns {(function(*): (*))|*}
 */
export const generateDataActorSelector = ({ entity, savedQuery }) => {
    return function (state) {
        let dataActorObj;
        if (entity) {
            if (savedQuery) {
                if (savedQuery?.id && savedQuery?.savedQueryObject) {
                    dataActorObj = findDataActorObjForEntitySavedQueryFromContext({ context: state.context, entity, savedQuery });
                }
            } else {
                dataActorObj = findDataActorObjForEntityFromContext({ context: state.context, entity });
            }
        }
        if (!!dataActorObj === true) {
            return dataActorObj;
        }
        return state.context.unknownDataActor;
    };
};
