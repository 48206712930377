import {get, set} from "lodash";
import {actions, assign, sendParent, spawn} from "xstate";
import {generateCreateDataMachine} from "@app/data/machine/generators/generateCreateDataMachine";
import {generateUpdateDataMachine} from "@app/data/machine/generators/generateUpdateDataMachine";
import {evaluateBobj, evaluateBobjList} from "@app/data/utils";
import {generateDeleteDataMachine} from "@app/data/machine/generators/generateDeleteDataMachine";
import {_getTransientObject} from "@app/data/machine/actors/optimisticDataActorMachine/utils";
import {generateLoadDataMachine} from "@app/data/machine/generators/generateLoadDataMachine";

const {pure, send} = actions;

const staticActionsThatRequireParent = {
    sendToParentRelatedBobjCreated: sendParent((context, event) => ({...event, type: `RELATED_TRANSIENT_BOBJ_CREATED`, relatedEntity: context?.entity})),
    sendToParentRelatedBobjDeleted: sendParent((context, event) => ({...event, type: `RELATED_TRANSIENT_BOBJ_DELETED`, relatedEntity: context?.entity}))
};

const generateStaticActions = ({noParent}) => ({
    addObjectForCreatingToTransientData: assign({
        transientData: (context, event) => {
            const {transientObject} = event;
            return [...context.transientData,
                {
                    object: transientObject
                }
            ];
        }
    }),
    addObjectForUpdatingToTransientData: assign({
        transientData: (context, event) => {
            const {keyProperty} = context;
            const {transientObject} = event;
            const transientData = context.transientData.filter((dataObj) => dataObj[keyProperty] !== transientObject[keyProperty]);
            return [
                ...transientData,
                {object: transientObject}
            ];
        }
    }),
    setLoadedData: assign({
        data: (context, event) => {
            return event.data.loadedData;
        },
        evaluatedData: (context, event) => {
            return evaluateBobjList({bobjList: event?.data?.loadedData});
        }
    }),
    addLoadedData: assign({
        data: (context, event) => {
            if (context.data.length === 0) {
                return event.loadedData;
            }
            return event.loadedData.map((obj) => {
                const currentObj = context.data.find((curObj) => curObj.id === obj.id) ?? {};
                return Object.assign(currentObj, obj);
            });
        },
        evaluatedData: (context, event) => {
            if (context.evaluatedData.length === 0) {
                return evaluateBobjList({bobjList: event.loadedData});
            }
            const newCombinedData = event.loadedData.map((obj) => {
                const currentObj = context.data.find((curObj) => curObj.id === obj.id);
                return Object.assign(currentObj, obj);
            });
            return evaluateBobjList({bobjList: newCombinedData});
        }
    }),
    processFreshData: assign((context, event) => {
        const {data, transientData, evaluatedData, keyProperty} = context;
        const newData = data;
        const newEvaluatedData = evaluatedData;
        const newTransientData = transientData;
        const freshData = event.data.freshData;
        // Get the fresh data that will be moved from transientData to data
        freshData.forEach((freshDataObj) => {
            const evaluatedFreshDataObj = evaluateBobj({bobj: freshDataObj});
            const foundTransientDataObjIndex = newTransientData.findIndex(
                ({object: transientDataObj}) => evaluatedFreshDataObj[keyProperty] === transientDataObj[keyProperty]
            );
            const {object} = newTransientData[foundTransientDataObjIndex];
            const indexOnData = data.findIndex((bobj) => bobj[keyProperty] === object[keyProperty]);
            if (indexOnData > -1) {
                newData[indexOnData] = freshDataObj;
                newEvaluatedData[indexOnData] = evaluatedFreshDataObj;
            } else {
                newData.unshift(freshDataObj);
                newEvaluatedData.unshift(evaluatedFreshDataObj);
            }
            newTransientData.splice(foundTransientDataObjIndex, 1);
        });

        return {
            data: newData,
            evaluatedData: newEvaluatedData,
            transientData: newTransientData
        };
    }),
    resetPollerDelay: assign({
        currentPollingDelay: (context) => context.startingPollingDelay
    }),
    increasePollerDelay: assign({
        currentPollingDelay: (context) => {
            const nextPollingDelay = context.currentPollingDelay * context.pollingDelayMultiplier;
            if (nextPollingDelay > context.maxPollingDelay) {
                return context.maxPollingDelay;
            }
            return nextPollingDelay;
        }
    }),
    updateObjectInTransientData: assign({
        transientData: (context, event) => {
            const {keyProperty} = context;
            const {object} = event;
            return context.transientData.map((transientDataObj) => {
                const {object: transientObject} = transientDataObj;
                if (object[keyProperty] !== transientObject[keyProperty]) {
                    return transientDataObj;
                }
                return {
                    ...transientDataObj,
                    object
                };
            });
        }
    }),
    addRelatedTransientObj: assign({
        relatedTransientObjMap: (context, event) => {
            const {relatedTransientObjMap: currentTransientObjMap, relatedEntityPropertyMap, keyProperty} = context;
            const {relatedEntity, transientObject} = event;
            const relatedAttributePathList = relatedEntityPropertyMap[relatedEntity];
            if (!relatedAttributePathList) {
                return currentTransientObjMap;
            }

            const nextRelatedTransientObjMap = {...currentTransientObjMap};
            relatedAttributePathList.forEach((path) => {
                let nextRelatedAttributeTransientObjList;
                const currentRelatedAttributeTransientObjList = currentTransientObjMap[path];
                if (!Array.isArray(currentRelatedAttributeTransientObjList)) {
                    nextRelatedAttributeTransientObjList = [transientObject];
                } else {
                    const transientObjAlreadyExists = currentRelatedAttributeTransientObjList.find((obj) => obj?.[keyProperty] === transientObject?.[keyProperty]);
                    if (transientObjAlreadyExists) {
                        nextRelatedAttributeTransientObjList = currentRelatedAttributeTransientObjList.map((obj) => {
                            if (obj?.[keyProperty] === transientObject?.[keyProperty]) {
                                return transientObject;
                            }
                            return obj;
                        });
                    } else {
                        nextRelatedAttributeTransientObjList = [...currentRelatedAttributeTransientObjList, transientObject];
                    }
                }
                nextRelatedTransientObjMap[path] = nextRelatedAttributeTransientObjList;
            });
            return nextRelatedTransientObjMap;
        }
    }),
    updateRelatedObjWithFreshObj: assign({
        relatedTransientObjMap: (context, event) => {
            const {relatedTransientObjMap: currentTransientObjMap, relatedEntityPropertyMap, keyProperty} = context;
            const freshData = event?.data?.freshData ?? [];
            const {relatedEntity} = event;
            const relatedAttributePathList = relatedEntityPropertyMap[relatedEntity];
            if (!relatedAttributePathList) {
                return currentTransientObjMap;
            }
            const freshDataObjKeyPropertyList = freshData.map((obj) => obj[keyProperty]);
            const nextRelatedTransientObjMap = {...currentTransientObjMap};
            relatedAttributePathList.forEach((path) => {
                const currentRelatedAttributeTransientObjList = currentTransientObjMap[path];
                if (currentRelatedAttributeTransientObjList) {
                    nextRelatedTransientObjMap[path] = currentRelatedAttributeTransientObjList.filter((transientObj) => !freshDataObjKeyPropertyList.includes(transientObj[keyProperty]));
                }
            });
            return nextRelatedTransientObjMap;
        },
        evaluatedData: (context, event) => {
            const {evaluatedData: currentEvaluatedData, relatedEntityPropertyMap, keyProperty} = context;
            const freshData = event?.data?.freshData ?? [];
            const {relatedEntity} = event;
            const relatedAttributePathList = relatedEntityPropertyMap[relatedEntity];
            if (!relatedAttributePathList) {
                return currentEvaluatedData;
            }
            const freshDataObjbyKeyPropertyMap = freshData.reduce((byIdMap, nextObj) => ({...byIdMap, [nextObj[keyProperty]]: nextObj}), {});
            return currentEvaluatedData.map((nextObj) => {
                relatedAttributePathList.forEach((nextPath) => {
                    const valAtProperty = get(nextObj, nextPath);
                    let nextValAtProperty = valAtProperty;
                    if (valAtProperty) {
                        if (Array.isArray(valAtProperty)) {
                            nextValAtProperty = valAtProperty.map((val) => {
                                if (freshDataObjbyKeyPropertyMap[val[keyProperty]]) {
                                    return freshDataObjbyKeyPropertyMap[val[keyProperty]];
                                }
                                return val;
                            });
                        } else {
                            if (freshDataObjbyKeyPropertyMap[valAtProperty[keyProperty]]) {
                                nextValAtProperty = freshDataObjbyKeyPropertyMap[valAtProperty[keyProperty]];
                            }
                        }
                    }
                    set(nextObj, nextPath, nextValAtProperty);
                });
                return nextObj;
            });
        }
    }),
    ...(noParent ? {} : staticActionsThatRequireParent)
});

export const optimisticDataActorMachineActionsBuilder = ({updateMachineOptions, createMachineOptions, loadMachineOptions, deleteMachineOptions, transientConfig, noParent}) => {
    const spawnLoadDataActor = assign({
        loadDataActorList: (context, event) => {
            const {projectionAttributeList} = event;
            const loadDataMachine = generateLoadDataMachine({context: {projectionAttributeList}, loadMachineOptions});
            return [...context.loadDataActorList, {
                projectionAttributeList,
                ref: spawn(loadDataMachine)
            }];
        }
    });


    const spawnCreateDataActor = assign({
        createDataActorList: (context, event) => {
            const {transientObject} = event;
            const createDataMachine = generateCreateDataMachine({context: {object: transientObject, gidAttributesList: event.gidAttributesList}, createMachineOptions});
            return [...context.createDataActorList,
                {
                    object: transientObject,
                    ref: spawn(createDataMachine)
                }
            ];
        }
    });

    const spawnUpdateDataActor = assign({
        updateDataActorList: (context, event) => {
            const {keyProperty} = context;
            const {transientObject} = event;
            const updateDataMachine = generateUpdateDataMachine({context: {object: {...transientObject}, fieldBeingUpdated: {...(event?.fieldBeingUpdated ?? {})}}, updateMachineOptions});
            const updateDataActorListWithoutCurrentTransientObject = context.updateDataActorList.filter((updateDataActorObj) => updateDataActorObj.object[keyProperty] !== transientObject[keyProperty]);
            return [
                ...updateDataActorListWithoutCurrentTransientObject,
                {
                    object: {...transientObject},
                    ref: spawn(updateDataMachine)
                }
            ];
        }
    });

    const deleteObjectFromData = assign({
        data: (context, event) => {
            const {keyProperty} = context;
            const {object} = event;
            spawn(generateDeleteDataMachine({context: {object}, deleteMachineOptions}));
            return context.data.filter((dataObj) => dataObj[keyProperty] !== event.object[keyProperty]);
        },
        evaluatedData: (context, event) => {
            const {keyProperty} = context;
            return context.evaluatedData.filter((dataObj) => dataObj[keyProperty] !== event.object[keyProperty]);
        },
        transientData: (context, event) => {
            const {keyProperty} = context;
            return context.transientData.filter((transientDataObj) => transientDataObj.object[keyProperty] !== event.object[keyProperty]);
        }
    });

    const generateTransientObjectToCreate = pure((context, event) => {
        const transientObject = _getTransientObject({event, transientConfig, transientValueGetter: transientConfig.transientValueOnCreate});
        return send({...event, type: "GENERATED_TRANSIENT_OBJECT_TO_CREATE", transientObject});
    });

    const generateTransientObjectToUpdate = pure((context, event) => {
        const transientObject = _getTransientObject({event, transientConfig, transientValueGetter: transientConfig.transientValueOnUpdate});
        return send({...event, type: "GENERATED_TRANSIENT_OBJECT_TO_UPDATE", transientObject});
    });


    return {
        spawnLoadDataActor,
        spawnCreateDataActor,
        spawnUpdateDataActor,
        deleteObjectFromData,
        generateTransientObjectToUpdate,
        generateTransientObjectToCreate,
        ...generateStaticActions({noParent})
    };
};
