/**
 * @typedef {Object} SweftOptimisticDataMachineServices
 * @property {function(SweftOptimisticDataMachineContext,SweftOptimisticDataMachineEvent):{ loadedData: Array<Object>}} loadService <b>Assign your event handler</b>
 */

export const optimisticDataActorMachineServicesBuilder = ({ servicesConfig, transientConfig }) => {
    const { loadDataFilteredForTransientData, ...restOfServicesConfig } = servicesConfig;
    const loadService = () => {
        console.warn("Default loadService called, check machine configuration.");
        return {
            loadedData: []
        };
    };

    return {
        loadService,
        loadDataFilteredForTransientData: async (context, event) => {
            const { transientData, keyProperty } = context;

            const { loadedData } = await loadDataFilteredForTransientData(context, event);
            const freshData = loadedData.filter((loadedDataObj) => {
                // Some services return the data in the root of the object
                // Some services return the data in a data property
                // Get this working for both
                const potentiallyFreshDataObj = loadedDataObj.data ? loadedDataObj.data : loadedDataObj;

                // Try and find this loadedObject in transientData
                const foundTransientDataObjFull = transientData.find(
                    ({ object: transientDataObj }) => transientDataObj[keyProperty] === potentiallyFreshDataObj[keyProperty]
                );

                // If the loaded object is not represented in transient data
                // then it is not meant to be processed now
                if (!foundTransientDataObjFull) {
                    return false;
                }

                // If the loaded object does not have a transientStamp
                // Then it is not meant to be processed now
                if (!potentiallyFreshDataObj[transientConfig.transientProperty]) {
                    return false;
                }

                const { object: foundTransientDataObj } = foundTransientDataObjFull;

                // If the loaded objects transientStamp is greater than or equal to the found objects transientStamp
                // Then the transient object propagated and this loadedObject is the fresh one
                return potentiallyFreshDataObj[transientConfig.transientProperty] >= foundTransientDataObj[transientConfig.transientProperty];
            });
            return { freshData };
        },
        ...restOfServicesConfig
    };
};
