// @ts-check
/* var BUILD_VER */
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import AuthService from "@app/services/authService";
import { signOut } from "supertokens-auth-react/recipe/emailpassword";
import { initialize } from './initializeSupertokens';
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { useSpecialty } from "@app/specialty/hooks/useSpecialty";
import { roleChecks } from "@app/auth/roleChecks";

initialize(); // The supertokens config

/**
 * Cache a user into localstorage for easy first-pass access
 * @param {User} user User data to cache into localstorage
 * @returns {void}
 */
export const storeUser = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
};

/**
 *
 * @returns {User}
 */
export const restoreUser = () => {
    let finalUser = null;
    try {
        const cachedUser = JSON.parse(localStorage.getItem('user'));
        if (cachedUser) {
            finalUser = {
                ...cachedUser,
                credentials: {}
            };
        }
    } catch (e) {
        return finalUser;
    }
    return finalUser;
};

export const noAuthContextAvailableError = new Error("Use of auth functionality before context is available");

/* Use of these before auth is initialized should result in an error */
export const throwNoAuthContextAvailableError = () => {
    throw noAuthContextAvailableError;
};

/**
 * Clear session and then reload in place
 */
export const logout = async () => {
    localStorage.clear();
    await signOut(); // Does not redirect
    window?.location?.reload();
};

/**
 * Defaults for the short time before the context is initialized
 * @type {React.Context<{ user: CredentialedUser, logout:(redirectCallback: ()=>any)=>void, refreshUser: ()=>void, refreshSession: ()=>void, isAdmin: boolean|undefined}>}
 */
export const AuthContext = createContext({
    user: null,
    logout: logout,
    refreshUser: throwNoAuthContextAvailableError,
    refreshSession: throwNoAuthContextAvailableError,
    isAdmin: undefined
});

/**
 * Use when you need a user or user.currentRole for access checks
 * @returns {{ user: CredentialedUser, logout:(redirectCallback: ()=>any)=>void, refreshUser: ()=>void, refreshSession: ()=>void, isAdmin: boolean|undefined}}
 }}
 */
export const useAuth = () => useContext(AuthContext);

/**
 * Allows for a standardized check of delta between user updates
 * @param {CredentialedUser | User} userPrevious
 * @param {CredentialedUser | User} userNew
 * @returns
 */
export const isUserDifferent = (userPrevious, userNew) => {
    // Compare users but ignore credentials differences
    return JSON.stringify({
        ...userPrevious,
        credentials: undefined
    }) !== JSON.stringify({
        ...userNew,
        credentials: undefined
    });
};



export const AuthWrapper = ({ children }) => {
    const session = useSessionContext();
    const cachedUser = restoreUser();
    const [user, setUser] = useState(cachedUser);
    const { specialty, setSpecialty } = useSpecialty();
    /**
     * Obtains the user from the backend,
     * is also used by role changes
     * @returns {Promise<CredentialedUser|null>}
     */
    const refreshUser = async () => {
        // @ts-ignore
        return await AuthService.selfInfo().then((res) => {
            const selfInfo = res.data;
            if (!user && selfInfo) { // No cache? just update
                setUser(selfInfo);
            } else { // Non-equivalent users avoid a reflow
                if (isUserDifferent(user, selfInfo)) {
                    setUser(selfInfo);
                }
            }
            storeUser(selfInfo); // Always store the latest
            return selfInfo;
        }).catch((e) => {
            console.error(e);
            storeUser(null);
            setUser(undefined);
            return null;
        });
    };

    const refreshSession = async () => {
        throw new Error("Not implemented");
    };

    useEffect(() => {
        !session?.loading && refreshUser();
    }, [session.loading, session.doesSessionExist]);

    useEffect(() => {
        if (!setSpecialty) {
            return;
        }
        if (user?.speciality) {
            // To ensure that if the specialty is there, it becomes available in context
            try {
                setSpecialty({
                    ...specialty,
                    isAvailable: true,
                    isEnabled: !(specialty?.isAvailable) ? true : !!specialty?.isEnabled,
                    config: JSON.parse(user?.speciality)
                });
            } catch (e) {
                console.error("Invalid specialty configuration on user info. See:", e);
            }
        } else {
            setSpecialty({ isAvailable: false, isEnabled: false, config: null });
        }
    }, [user, setSpecialty]);


    /**
     * Use to check if current user is a super admin
     * @type {boolean}
     */
    const isSuperAdmin = useMemo(() => {
        return roleChecks.isRoleSuperAdmin(user?.currentRole ?? '');
    }, [user?.currentRole]);


    /**
     * Use to check if current user is an admin
     * @returns {boolean}
     */
    const isAdmin = useMemo(() => {
        return roleChecks.isRoleAdmin(user?.currentRole) || isSuperAdmin;
    }, [isSuperAdmin, user?.currentRole]);


    /**
     * Set up all the pieces of the context!
     */
    const contextValue = useMemo(() => ({
        user, isAdmin, isSuperAdmin, logout, refreshUser, refreshSession
    }), [user, isAdmin, isSuperAdmin]);


    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );
};
