import { createMachine } from "xstate";
/**
 * A machine for calling an updateService and passing in an object to update via the context
 *
 * @param context - The object that will be updated
 * @param updateMachineOptions - Machine options (actions, services, guards)
 * @returns {StateMachine}
 */
export const generateUpdateDataMachine = ({ context, updateMachineOptions }) => {
    return createMachine({
        id: `updateData`,
        context,
        initial: "callingUpdateService",
        states: {
            callingUpdateService: {
                invoke: {
                    src: "updateService",
                    onDone: {
                        target: 'done',
                    },
                },
            },
            done: {
                type: "final"
            }
        }
    }, {
        ...updateMachineOptions
    });
};
