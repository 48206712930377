// @ts-check
import React, { useCallback } from "react";
import UserService from "@app/services/userService";
import { Spin } from 'antd';

/**
 * Split out mechanism to handle the saving of the role change
 * @param {CredentialedUser} reqData
 * @param {string} newCurrentRole
 * @param {()=>void} modalClose
 */
export const saveRoleChange = (reqData, newCurrentRole, modalClose, refreshUser, navigate) => {
    return UserService.updateCurrentRole(reqData.userId, newCurrentRole).then(async (data) => {
        if (data.status) {
            await refreshUser();
            modalClose();
            navigate('/dashboard');
        } else {
            modalClose();

            console.log({
                message: data.data.responseMessage,
                type: 4,
            });
        }
    }).catch((error) => {
        modalClose();

        console.log({
            message: `Failed ${error}`,
            type: 4,
        });
    });
};

/**
 * Gather the roles in a dropdown and change user on selecting a new role
 * @prop {CredentialedUser} user
 * @prop {{roleId: string, displayName: string, enabled: boolean}[]} systemRoles
 * @prop {()=>void} modalClose
 * @returns
 */
const RolesDropdown = ({ user, systemRoles, onClose, saveRoleChange, refreshUser, navigate, loading = false }) => {
    const [loadingChanges, setLoadingChanges] = React.useState(loading);

    const optionItem = useCallback(() => {
        let optionItem = [];
        if (!user || !systemRoles) {
            return optionItem;
        }
        const { roles: userRoles } = user;

        // Just loop through the system roles (matching on any that the user also has)
        optionItem = systemRoles?.map((sRole) => {
            const matchingRole = userRoles.length && userRoles?.find((uRole) => uRole.roleId == sRole.roleId);
            if (matchingRole && sRole.enabled) {
                return <option value={sRole.roleId} key={sRole.roleId}>{sRole.displayName}</option>;
            }

            return '';
        });

        return optionItem;
    }, [user, systemRoles]);

    const changeUserGroup = useCallback(async (event) => {
        const { target } = event;
        const newCurrentRole = target.type === 'checkbox' ? target.checked : target.value;

        if (user && newCurrentRole) {
            setLoadingChanges(true);
            const completed = await saveRoleChange(user, newCurrentRole, onClose, refreshUser, navigate);
            setLoadingChanges(false);
        } else {
            console.error('No user or new current role');
        }
    }, [user, onClose, saveRoleChange, refreshUser, navigate]);

    return (
        <div className="form-group">
            <label className="form-label" htmlFor="current-role-dropdown-combobox">User Group:&nbsp;</label>
            <select className="form-control" name="currentRole" id="current-role-dropdown-combobox" disabled={loadingChanges} onChange={changeUserGroup} value={user?.currentRole}>
                {optionItem()}
            </select>{' '}
            {loadingChanges && <Spin size="small"/>}
        </div>
    );
};

export default RolesDropdown;
