import request from './request';
import { v4 as uuidv4 } from 'uuid';
import { baseConfig } from '@app/config/baseConfig';

const ns = 'a76eb368-67fc-41bf-8409-5737397d8de6';

const configurationApi = `${baseConfig.GATEWAY_API_BASE_PROTOCOL}//api.${baseConfig.GATEWAY_API_BASE}/configuration`;

/**
 * Creating this wrapper around alerts that uses the config backend
 * to allow feature flag toggling between new and legacy alerts
 * Once new alerts goes completely alive, remove this and the flag
 * @deprecated
 */
const AlertConfigService = {
    /**
     * Check status of the alert legacy service
     * Gives a 401 when no session is available
     * @returns {Promise<unknown>}
     */
    status: async () => {
        return await request({
            method: "GET",
            url: `${configurationApi}/status`
        });
    },
    getAlerts: async () => {
        const response = await request({
            method: "GET",
            url: `${configurationApi}/alerts`
        });
        return response.data.data;
    },
    saveAlert: async (config) => {
        if (!config?.configurationId) {
            config.configurationId = uuidv4();
        }
        const response = await request({
            method: "PUT",
            url: `${configurationApi}/alerts/${config.configurationId}`,
            data: config
        });

        return response.data;
    },
    getAlert: async (configId) => {
        const response = await request({
            method: "GET",
            url: `${configurationApi}/alerts/${configId}`
        });
        return response.data;
    },
    removeAlert: async (config) => {
        const response = await request({
            method: "DELETE",
            url: `${configurationApi}/alerts/${config.configurationId}`,
        });

        return response.data;
    },
    getEntityAlerts: async (entity) => {
        throw new Error('Not implemented in legacy api');
    },
};

export default AlertConfigService;
