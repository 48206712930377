import React from "react";
import { Link } from "react-router-dom";
import AnimatePing from "@app/common/AnimatedPing";

export const DashboardModuleAlertTile = ({ alert, useAlertStatus }) => {
    const { isLoading, savedQueryCount: count } = useAlertStatus({ alert });
    return <Link to={alert?.path ?? "#"} role="region" className="flex group w-full justify-between items-center rounded-lg !bg-gray-50 px-4 py-4 text-teal-600  hover:!bg-teal-400 group hover:text-white cursor-pointer">
        <span className="text-3xl text-gray-500 group-hover:text-white">{alert?.displayName}</span>
        {isLoading ? <AnimatePing/> : <span className="font-bold text-3xl">{count}</span>}
    </Link>;
};
