import React from "react";
import { EntitlementsWrapper } from "@app/contexts/useEntitlements";
import { MessagesWrapper } from "@app/contexts/useMessages";
import { LayoutContextWrapper } from "@app/layout/contexts/useLayout";
import { WorkspacesWrapper } from "@app/workspace/contexts/useWorkspaces";
import { AlertsWrapper } from "@app/contexts/useAlerts";
import { ConfigDataWrapper } from "@app/data/config/context";

const dataContexts = [
    ConfigDataWrapper,
    EntitlementsWrapper,
    MessagesWrapper,
    WorkspacesWrapper,
    LayoutContextWrapper,
    AlertsWrapper
];

export const DataContextsWrapper = dataContexts.reduce((DataContextsWrapperAcc, NextDataContextWrapper) => function ({ children }) {
    return <DataContextsWrapperAcc>
        <NextDataContextWrapper>
            {children}
        </NextDataContextWrapper>
    </DataContextsWrapperAcc>;
},);
