import React, { useMemo } from "react";
import { DashboardModule } from "@app/dashboard/controls/DashboardModule";
import { createSearchParams, Link } from "react-router-dom";
import { useWorkspaces } from "@app/workspace/contexts/useWorkspaces";
import { applyAdvancedFilters } from "@app/grid/advancedFilters/advancedFilters";
import AnimatePing from "@app/common/AnimatedPing";
import { useDataActor } from "@app/data/useDataActor";
import { base64Encode } from "@app/common/utils";

/**
 * These values correspond to the values that are applied to the stageStatusFormula attribute
 * @type {string[]}
 */
const stageStatusValues = [
    "Order Entry",
    "Vendor Confirmation",
    "Style and PO Creation",
    "Vendor Content Entry",
];

/**
 * These have not been configured on the formula.
 * When they're configured, they can be moved to the stageStatusValues array
 * @type {string[]}
 */
const notConfiguredStageStatusValues = [
    "Sample Management",
    "Product Photography",
    "Post Photo Production",
    "Copy Writing",
    "Publish",
    "Final Product Content",
    "Published"
];

/**
 * Creates a JSON Logic query for looking for a given stage status value on the stageStatusFormula attribute
 * @param stageStatusValue
 * @returns {{logic: {and: [{"==": [{var: "stageStatusFormula"},stageStatusValue]}]}}}
 */
const createStageStatusQuery = (stageStatusValue) => {
    return {
        logic: {
            and: [{
                "==": [
                    { "var": "stageStatusFormula" },
                    stageStatusValue
                ]
            }]
        }
    };
};

/**
 * o
 * @type {{query: {logic: {and: {"==": [{var: "stageStatusFormula"},stageStatusValue]}[]}}, queryParam: URLSearchParams, stageStatusValue: *}[]}
 */
const stageStatusQueries = stageStatusValues.map((stageStatusValue) => {
    const query = createStageStatusQuery(stageStatusValue);
    const encodedQuery = base64Encode(JSON.stringify(query));
    const queryParam = createSearchParams({ gridAdvancedFilters: encodedQuery });
    return {
        stageStatusValue,
        query,
        queryParam
    };
});

export const DashboardModuleStageStatus = ({}) => {
    const { findWorkspaceObjBasedOnId } = useWorkspaces();

    const productWorkspaceObj = findWorkspaceObjBasedOnId("product");
    const { dataActorState, dataActorData: primaryEntityData } = useDataActor({ entity: productWorkspaceObj?.primaryEntity });

    const stageStatusesWithCounts = useMemo(() => {
        return stageStatusQueries.map((stageStatusQueryObj) => {
            const processedRows = applyAdvancedFilters({ logic: stageStatusQueryObj.query.logic, rows: primaryEntityData });
            return {
                ...stageStatusQueryObj,
                matchCount: processedRows.length
            };
        });
    }, [primaryEntityData]);

    const isLoading = !dataActorState?.matches("loader.idle");

    return (
        <DashboardModule className="col-start-8 col-end-13 row-span-3">
            <DashboardModule.Header>
                <i className="icon-stage-status-clipboard"/>
                <DashboardModule.Title>
                    Stage Status
                </DashboardModule.Title>
            </DashboardModule.Header>
            <DashboardModule.Body className="px-0 py-0">
                {stageStatusesWithCounts?.map((stageStatusObj) => {
                    const to = `${productWorkspaceObj?.specifications?.path}?${stageStatusObj?.queryParam}`;
                    return (
                        <Link key={to} to={to} className="flex items-center justify-between px-4 py-6  border-b hover:bg-teal-50 hover:bg-opacity-20 hover:text-teal-900 text-black text-2xl">
                            <span>{stageStatusObj?.stageStatusValue}</span>
                            {isLoading ? <AnimatePing/> : <span className="font-bold">{stageStatusObj?.matchCount}</span>}
                        </Link>
                    );
                })}
                {
                    notConfiguredStageStatusValues.map((notConfiguredStageStatusValue) => (
                        <div key={notConfiguredStageStatusValue} className="flex items-center justify-between px-4 py-6  border-b text-black text-2xl opacity-40 cursor-default">
                            <span>{notConfiguredStageStatusValue}</span>
                            <span>-</span>
                        </div>
                    ))
                }
            </DashboardModule.Body>
        </DashboardModule>
    );
};
