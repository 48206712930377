import { flagNames, isFlagOn } from "@app/common/featureFlags";
import { sortObjKeys } from "@app/common/utils";

/**
 * Returns the visible column list for super admin based on state conditions, such as feature flags
 * @param columnList
 * @returns {*}
 */
export const getVisibleColumnListForSuperAdmin = ({ columnList = [] } = {}) => {
    const disabledAttributesHiddenForSuperAdmin = isFlagOn(flagNames.disabledAttributesHiddenForSuperAdmin);
    if (disabledAttributesHiddenForSuperAdmin) {
        return columnList.filter((col) => col.state === 'On');
    }
    return columnList;
};
/**
 * Get related attribute maps for each entity found from the column list
 * @param columnList
 * @returns {{entityToRelatedAttributeMap: {}, entitiesToRelatedEntityMap: {}}}
 */
export const createEntityToRelatedAttributeMaps = ({ columnList = [] } = {}) => {
    const entityToRelatedAttributeMap = {};
    const entitiesToRelatedEntityMap = {};
    const entitySet = new Set(columnList.map(cols => cols.entity));

    entitySet.forEach((entity) => {
        const relatedAttributeSet = new Set();
        const relatedEntitySet = new Set();
        columnList.forEach((column) => {
            if (entity === column?.entity && entitySet.has(column?.relation)) {
                relatedAttributeSet.add(column);
                relatedEntitySet.add(column?.relation);
            }
        });
        entityToRelatedAttributeMap[entity] = Array.from(relatedAttributeSet);
        entitiesToRelatedEntityMap[entity] = Array.from(relatedEntitySet);
    });

    return { entityToRelatedAttributeMap, entitiesToRelatedEntityMap };
};


/**
 * Recursively reduce a list of attribute objects to an object that
 * @param attributeList
 * @param entityToRelatedAttributeMap
 * @returns {{}}
 */
export const reduceAttributeListToRelatedAttributePathMap = ({ attributeList = [], entityToRelatedAttributeMap = new Map() } = {}) => {
    const obj = attributeList.reduce((pathMapAcc, nextAttributeObj) => {
        const relatedEntity = nextAttributeObj.relation;
        const relatedEntityField = nextAttributeObj.schemaId.split(".")[1];
        let relatedEntityAttributePathMapPrefixed = {};
        if (entityToRelatedAttributeMap.has(relatedEntity)) {
            const relatedEntityAttributeList = entityToRelatedAttributeMap.get(relatedEntity);
            const entityToRelateAttributeMapWithoutRelatedEntity = new Map(entityToRelatedAttributeMap);
            entityToRelateAttributeMapWithoutRelatedEntity.delete(relatedEntity);
            const relatedEntityAttributePathMap = reduceAttributeListToRelatedAttributePathMap({ attributeList: relatedEntityAttributeList, entityToRelatedAttributeMap: entityToRelateAttributeMapWithoutRelatedEntity });
            relatedEntityAttributePathMapPrefixed = Object.keys(relatedEntityAttributePathMap).reduce((prefixedMap, nextUnprefixedPathKey) => {
                return {
                    ...prefixedMap,
                    [`${relatedEntityField}.${nextUnprefixedPathKey}`]: relatedEntityAttributePathMap[nextUnprefixedPathKey]
                };
            }, {});
        }

        return {
            ...pathMapAcc,
            [relatedEntityField]: relatedEntity,
            ...relatedEntityAttributePathMapPrefixed
        };
    }, {});
    return sortObjKeys({ obj });
};

/**
 * Returns a nested object structure where each top-level key is an entity and their value is a map.
 * Each key of the value map is a related attribute path and their value is an entity.
 *
 * @param entityToRelatedAttributeMap
 * @returns {{}}
 */
export const createEntityToRelatedAttributePathMap = ({ entityToRelatedAttributeMap = {} }) => {
    const entityList = Object.keys(entityToRelatedAttributeMap);
    const mapOfEntityToRelatedAttribute = new Map(Object.entries(entityToRelatedAttributeMap));
    const entityToRelatedAttributePathMap = entityList.reduce((reductionMap, nextEntity) => {
        const attributeList = entityToRelatedAttributeMap[nextEntity];
        const pathMap = reduceAttributeListToRelatedAttributePathMap({ attributeList, entityToRelatedAttributeMap: mapOfEntityToRelatedAttribute });
        return {
            ...reductionMap,
            [nextEntity]: pathMap
        };
    }, {});

    return Object.keys(entityToRelatedAttributePathMap).reduce((reductionMap, nextEntity) => {
        const entityPathObj = entityToRelatedAttributePathMap[nextEntity];
        const unreachablePaths = [];
        return {
            ...reductionMap,
            [nextEntity]: Object.keys(entityPathObj).reduce((objReductionMap, nextPath) => {
                const entityVal = entityPathObj[nextPath];
                if (entityVal === nextEntity || unreachablePaths.some((path) => nextPath.startsWith(path))) {
                    unreachablePaths.push(nextPath);
                    return objReductionMap;
                }
                return {
                    ...objReductionMap,
                    [nextPath]: entityVal
                };
            }, {})
        };
    }, {});
};
