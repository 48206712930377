import React, { lazy, Suspense } from 'react';

const SweftWorkspaceContainer = lazy(() => import(/* webpackChunkName: "SweftWorkspace" */ "@app/workspace/SweftWorkspace"));
import Header from "@app/layout/header/header";
import LeftNav from './leftnav/leftNavContainer';
import './sweftLayout.less';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import ErrorBoundryComponent from '../common/error-boundry-component';
import { useLayout } from "@app/layout/contexts/useLayout";
import { useWorkspaces } from "@app/workspace/contexts/useWorkspaces";
import SuspenseFallbackLoader from "@app/common/suspenseFallbackLoader";
import MenuDrawer from "@app/layout/drawer/menuDrawer";
import DashboardComponent from '@app/dashboard/dashboardComponent';
import { LoadingSpinner } from "@app/common/loadingSpinner";
import { useEntitlements } from "@app/contexts/useEntitlements";
import PageNotFound from "@app/common/404/404";

const LayoutWrapper = () => {
    const { isFullscreenWorkspace } = useLayout();
    const { currentWorkspace } = useWorkspaces();

    const setContentHeight = () => {
        const heightObj = {};
        if (currentWorkspace?.id === "dashboard") {
            return null;
        }
        heightObj.height = 'calc(100% - 64px)';
        return heightObj;
    };
    return (
        <div className={`layout-root ${isFullscreenWorkspace ? 'full-screen-workspace' : ''}`}>
            <div className="sidenav">
                <ErrorBoundryComponent>
                    <LeftNav/>
                </ErrorBoundryComponent>
            </div>

            <div className="right-content">
                <div id="rightContent" className="flex w-full h-full flex-col">
                    <ErrorBoundryComponent>
                        <Header/>
                    </ErrorBoundryComponent>
                    <section id="workspaceContent" className="flex h-full w-full overflow-hidden" style={setContentHeight()}>
                        <ErrorBoundryComponent>
                            <MenuDrawer/>
                        </ErrorBoundryComponent>
                        <ErrorBoundryComponent>
                            <Outlet/>
                        </ErrorBoundryComponent>
                    </section>
                </div>
            </div>
            <div
                className="notifier-container"
                id="notifier-container"
            />
        </div>
    );
};

const SweftLayout = () => {
    const { initialEntitlementsLoadComplete } = useEntitlements();
    const { workspaceList } = useWorkspaces();

    if (!initialEntitlementsLoadComplete) {
        return <LoadingSpinner/>;
    }

    return (
        <Routes>
            <Route element={<LayoutWrapper/>}>
                <Route path="/" element={<Navigate replace={true} to="/dashboard"/>}/>
                <Route path="/dashboard" element={<Suspense fallback={<SuspenseFallbackLoader/>}><DashboardComponent/></Suspense>}/>
                {
                    workspaceList.map((workspace) => (
                        <Route
                            key={workspace?.specifications?.path}
                            path={workspace?.specifications?.path}
                            element={<Suspense fallback={<SuspenseFallbackLoader/>}>{workspace?.element ? workspace?.element : <SweftWorkspaceContainer/>}</Suspense>}
                        />))
                }
            </Route>
            <Route path="/404" element={<PageNotFound/>}/>
            <Route path="*" element={<Navigate replace={true} to="/404"/>}/>
        </Routes>
    );
};


export default SweftLayout;
