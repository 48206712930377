import { base64Encode, findInList } from "@app/common/utils";
import { createSearchParams } from "react-router-dom";
import { flagNames, isFlagOn } from "@app/common/featureFlags";

const primaryAttr = isFlagOn(flagNames.alertStandaloneReadApiEnabled) ? "alertId" : "configurationId";

const Joi = require('joi');

/**
 * @type {Joi.ObjectSchema<Partial<SweftAlertConfig>>}
 */
const baseAlertConfigSchema = Joi.object().keys({
    [primaryAttr]: Joi.string().required(),
    displayName: Joi.string().required(),
    description: Joi.string().allow(''),
    workspace: Joi.string().required(),
    savedQueryObject: Joi.object().required()
});
/**
 * @type {Joi.ObjectSchema<SweftAlertConfig>}
 */
const alertConfigSchema = baseAlertConfigSchema.keys({
    modified: Joi.string(),
    modifiedTimestamp: Joi.string(),
    typeKey: Joi.string()
});

export const validateAlert = (alert = {}) => {
    const { error } = alertConfigSchema.validate(alert, { abortEarly: false, errors: { label: "path" } });
    if (error) {
        console.log({ error });
        throw error;
    }
    return alert;
};

/**
 * Filters the alert list for only the alerts included in the role's alert configuration list that are visible
 * with relevant alert values replaced with those of the role alert config.
 *
 * Values that are replaced: displayName
 *
 * @param {Array<SweftAlertConfig>} alertList
 * @param {SweftRoleConfig} roleObj
 * @returns {Arrays<SweftAlertConfig>}
 */
export const getAlertsForRole = (alertList = [], roleObj = {}) => {
    const roleAlertConfigList = roleObj?.alertList ?? [];

    return roleAlertConfigList?.reduce(
        /**
         * @param {Arrays<SweftAlertConfig>} roleAlertsAcc
         * @param {SweftRoleAlertConfig} nextRoleAlertConfig
         * @returns {Arrays<SweftAlertConfig>}
         */
        (roleAlertsAcc, nextRoleAlertConfig = {}) => {
            if (!nextRoleAlertConfig?.visible) {
                return roleAlertsAcc;
            }
            const alert = findInList(alertList, { configurationId: nextRoleAlertConfig?.id });

            if (!alert) {
                return roleAlertsAcc;
            }

            const roleAlert = {
                ...alert,
                displayName: nextRoleAlertConfig?.name || alert.displayName
            };
            return [...roleAlertsAcc, roleAlert];
        }, []);
};

export const generateAlertDestinationPathQueryString = ({ alert }) => {
    const gridAdvancedFilters = base64Encode(JSON.stringify({ logic: alert?.savedQueryObject }));
    return createSearchParams({ gridAdvancedFilters });
};

export const hydrateAlertObj = ({ alert, workspaceObj }) => {
    const gridAdvancedFilters = base64Encode(JSON.stringify({ logic: alert?.savedQueryObject }));
    const alertQueryString = generateAlertDestinationPathQueryString({ alert });
    const destinationPath = workspaceObj?.specifications?.path;
    const entity = workspaceObj?.primaryEntity;

    return {
        ...alert,
        entity,
        path: `${destinationPath}?${alertQueryString}`,
        gridAdvancedFilters,
        alertWorkspaceObj: workspaceObj,
    };
};
