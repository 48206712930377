import { SavedQueryService } from "@app/services/savedQueryService";
import { actions, assign, createMachine, sendParent } from "xstate";
import AlertService from "@app/services/alertService";

const { pure } = actions;

/**
 * @interface SweftAlertsDataMachineContext
 * @prop {Object|null} userRole
 * @prop {Object|null} userSpecialty
 * @prop {SweftSavedQueryAlertListMap} userAlertListByEntityMap
 * @prop {{[alertId: string]: string }} savedQueryIdByAlertIdMap
 */

/**
 * @typedef SweftAlertsDataMachineEvent
 * @type {
 *  { type: 'USER_ROLE_CHANGED', nextUserRole: Object }
 *  }
 */

/**
 * @typedef SweftAlertsDataMachine
 * @type {StateMachine<SweftAlertsDataMachineContext, SweftAlertsDataMachineEvent>}
 */

/**
 * @type {SweftAlertsDataMachineContext}
 */
export const alertsDataActorMachine = createMachine({
    id: `alertsDataActor`,
    initial: "idle",
    context: {
        userRole: null,
        userSpecialty: null,
        userAlertListByEntityMap: {},
        savedQueryIdByAlertIdMap: {},
    },
    states: {
        gettingAlertList: {
            invoke: {
                src: "getAlertsForRole",
                onDone: {
                    actions: "setAlertListByEntityMap",
                    target: "gettingSavedQueryIdList",
                },
            },
        },
        gettingSavedQueryIdList: {
            invoke: {
                src: "getSavedQueryIdListFromAlertIdList",
                onDone: {
                    actions: "setSavedQueryIdByAlertIdMap",
                    target: "spawningEntitySavedQueryCountsActors",
                },
            },
        },
        spawningEntitySavedQueryCountsActors: {
            entry: "sendEventToSpawnEntitySavedQueryCountsActors",
            always: {
                target: "idle",
            },
        },
        idle: {},
    },
    on: {
        USER_ROLE_CHANGED: {
            actions: "setNextUserRole",
            target: "gettingAlertList"
        },
        USER_SPECIALTY_CHANGED: {
            actions: "setNextUserSpecialty",
        }
    },
}, {
    actions: {
        setNextUserRole: assign({
            userRole: (context, event) => event?.nextUserRole ?? context?.userRole
        }),
        setNextUserSpecialty: assign({
            userSpecialty: (context, event) => event?.nextUserSpecialty ?? context?.userSpecialty
        }),
        setAlertListByEntityMap: assign({
            userAlertListByEntityMap: (context, event) => {
                return event.data.reduce((currentAlert, nextAlert) => ({
                    ...currentAlert,
                    [nextAlert.entity]: [...(currentAlert[nextAlert.entity] || []), nextAlert]
                }), {});
            }
        }),
        setSavedQueryIdByAlertIdMap: assign({
            savedQueryIdByAlertIdMap: (context, event) => {
                return event.data.reduce((currentAlertId, nextAlertId) => ({ ...currentAlertId, ...nextAlertId }), {});
            }
        }),
        sendEventToSpawnEntitySavedQueryCountsActors: pure((context) => {
            const { userAlertListByEntityMap, savedQueryIdByAlertIdMap, userSpecialty } = context;

            // Limit Map of Alerts To Those With SavedQueryId
            const limitedAlertListByEntityMap = Object.keys(userAlertListByEntityMap).reduce((limitedMap, nextEntity) => {
                const entityAlertList = userAlertListByEntityMap[nextEntity];
                const entityAlertListWithSavedQueryId = entityAlertList.reduce((alertListWithSavedQueryId, nextAlert) => {
                    const savedQueryId = savedQueryIdByAlertIdMap[nextAlert.alertId];
                    if (savedQueryId) {
                        return [
                            ...alertListWithSavedQueryId,
                            {
                                ...nextAlert,
                                savedQueryId
                            }
                        ];
                    }
                    return alertListWithSavedQueryId;
                }, []);

                if (entityAlertListWithSavedQueryId.length !== 0) {
                    return {
                        ...limitedMap,
                        [nextEntity]: entityAlertListWithSavedQueryId
                    };
                }
                return limitedMap;
            }, {});

            const entitySavedQueryCountsDataActorSpawnCommands = Object.keys(limitedAlertListByEntityMap).map((entity) => {
                const savedQueryIdList = Array.from(new Set(limitedAlertListByEntityMap[entity].map((alertWithSavedQueryId) => alertWithSavedQueryId.savedQueryId)));
                return sendParent({ type: "COMMAND_SPAWN_ENTITY_SAVED_QUERY_COUNTS_DATA_ACTOR", entity, savedQueryIdList });
            });

            let entitySavedQueryCountsWithAdditionalJsonLogicDataActorSpawnCommands = [];
            if (Array.isArray(userSpecialty)) {
                const workspaceSpecialtyAlertListByEntityMap = Object.keys(limitedAlertListByEntityMap).reduce((mapOfAlertsWithWorkspaceSpecialty, nextEntity) => {
                    const entityAlertList = limitedAlertListByEntityMap[nextEntity];
                    const entityAlertListWithWorkspaceSpecialty = entityAlertList.reduce((alertListWithWorkspaceSpecialty, nextAlert) => {
                        const alertWorkspaceSpecialty = userSpecialty.find((workspaceSpecialtyObj) => workspaceSpecialtyObj.workspace = nextAlert.workspace);
                        if (alertWorkspaceSpecialty) {
                            return [
                                ...alertListWithWorkspaceSpecialty,
                                {
                                    ...nextAlert,
                                    additionalJsonLogicQueryObject: alertWorkspaceSpecialty.savedQueryObject
                                }
                            ];
                        }
                        return alertListWithWorkspaceSpecialty;
                    }, []);

                    if (entityAlertListWithWorkspaceSpecialty.length !== 0) {
                        return {
                            ...mapOfAlertsWithWorkspaceSpecialty,
                            [nextEntity]: entityAlertListWithWorkspaceSpecialty
                        };
                    }
                    return mapOfAlertsWithWorkspaceSpecialty;
                }, {});

                entitySavedQueryCountsWithAdditionalJsonLogicDataActorSpawnCommands = Object.keys(workspaceSpecialtyAlertListByEntityMap).map((entity) => {
                    const savedQueryIdList = Array.from(new Set(workspaceSpecialtyAlertListByEntityMap[entity].map((alertWithSavedQueryId) => alertWithSavedQueryId.savedQueryId)));
                    const { additionalJsonLogicQueryObject } = workspaceSpecialtyAlertListByEntityMap[entity][0];
                    return sendParent({ type: "COMMAND_SPAWN_ENTITY_SAVED_QUERY_COUNTS_DATA_ACTOR", entity, savedQueryIdList, additionalJsonLogicQueryObject });
                });
            }

            return [...entitySavedQueryCountsDataActorSpawnCommands, ...entitySavedQueryCountsWithAdditionalJsonLogicDataActorSpawnCommands];
        })
    },
    services: {
        /**
         * @param {SweftAlertsDataMachineContext} context
         * @param {SweftAlertsDataMachineEvent} event
         * @returns {Promise<[]>} roleAlertList
         */
        getAlertsForRole: async (context) => {
            const { userRole } = context;
            const alertList = await AlertService.getAlerts();
            const userRoleVisibleAlertIdList = userRole.alertList.filter((alertObj) => alertObj.visible).map((alert) => alert.id);
            return alertList.filter((alertObj) => userRoleVisibleAlertIdList.includes(alertObj.alertId));
        },
        getSavedQueryIdListFromAlertIdList: async (context) => {
            const createSavedQueryIdListFromAlertIdList = Object.keys(context.userAlertListByEntityMap).map(async (entity) => {
                return new Promise((resolve, reject) => {
                    SavedQueryService.getSavedQueryListByReferenceIdList({ idList: context.userAlertListByEntityMap[entity].map((alertObj) => alertObj.alertId) }).then((savedQueryList) => {
                        const getAlertIdMap = context.userAlertListByEntityMap[entity].map((alertObj) => {
                            if (savedQueryList.data && savedQueryList.data.length > 0) {
                                return { [alertObj.alertId]: savedQueryList?.data.find(savedQueryObj => savedQueryObj.referenceId === alertObj.alertId).savedQueryId };
                            }
                            return null;
                        }).filter(hasQuery => hasQuery);
                        if (savedQueryList.status === 200) {
                            resolve(getAlertIdMap);
                        }
                    }).catch((err) => {
                        reject(err);
                    });
                });
            });

            return await Promise.all(createSavedQueryIdListFromAlertIdList).then((savedQueryList) => {
                return savedQueryList.flat();
            }).catch((error) => {
                console.error(error);
            });
        },
    },
    guards: {},
});
