// @ts-check
/* eslint-disable max-lines */

import { evaluatedDataSelector } from "@app/data/machine/selectors";
import { formulaProcessors, isObject } from "@app/common/utils";
import { generateDataActorSelector } from "@app/data/machine/generators/generateDataActorSelector";
import { isEqual } from "lodash";

/**
 * Map of formula attributes by attribute id (entity.attribute)
 * Used for re-evaluating formula attributes after the formula attribute has been evaluated
 * And the formula properties are no longer available on the bobj
 * @type {{ [`entity.attribute`] : { type: string, value: string}}}
 */
export const formulaAttributeMapByAttributeId = {};

/**
 * An object that does not have any properties with values that are formula definition objects.
 * Formula definitions are objects with type and value property.
 * @typedef SweftEvaluatedObject
 * @type {Object}
 */

/**
 * Evaluates the formula attributes of the bobj, replacing the formula definition objects with the evaluated value.
 * Because the formula definitions are being replaced, a copy of the formula definition is kept in the
 * formulaAttributeMapByAttributeId map. The next time this bobj needs to be evaluated, the formula definition is accessed
 * from the map.
 * @param {Object} bobj
 * @returns {SweftEvaluatedObject}
 */
export const evaluateBobj = ({ bobj = {} } = {}) => Object.keys(bobj).reduce((evaluatedBobj, nextKey) => {
    const nextValue = bobj[nextKey];
    if (!isObject(nextValue)) {
        if (formulaAttributeMapByAttributeId[`${bobj.entity}.${nextKey}`]) {
            const { type, value } = formulaAttributeMapByAttributeId[`${bobj.entity}.${nextKey}`];
            const processedValue = formulaProcessors[type](bobj, value);
            return {
                ...evaluatedBobj,
                [nextKey]: processedValue
            };
        }
        return {
            ...evaluatedBobj,
            [nextKey]: nextValue
        };
    }
    const { type = null, value = null, ...restOfObjectValue } = nextValue;
    if (Object.keys(restOfObjectValue).length !== 0) {
        return {
            ...evaluatedBobj,
            [nextKey]: nextValue
        };
    }
    if (type !== null && value !== null && formulaProcessors[type]) {
        const processedValue = formulaProcessors[type](bobj, value);
        formulaAttributeMapByAttributeId[`${bobj.entity}.${nextKey}`] = { type, value };
        return {
            ...evaluatedBobj,
            [nextKey]: processedValue
        };
    }
    return {
        ...evaluatedBobj,
        [nextKey]: null
    };
}, {});

export const evaluateBobjList = ({ bobjList = [] } = {}) => {
    if (Array.isArray(bobjList)) {
        return bobjList.map((bobj) => evaluateBobj({ bobj }));
    }
    return [];
};

/**
 * Returns the data for the provided in entity that is in the entity data actor of the provided in interpreted machine
 * @type {Object}
 * @param {SweftDataMachineService} machineService
 * @param {string} entity
 * @returns {[]|*}
 */
export const getActorDataFromMachineService = ({ machineService, entity }) => {
    const dataActorSelector = generateDataActorSelector({ entity });
    const dataActor = dataActorSelector(machineService.getSnapshot());
    return evaluatedDataSelector(dataActor.ref.getSnapshot());
};

export const getActorRefFromMachineService = ({ machineService, entity }) => {
    const dataActorSelector = generateDataActorSelector({ entity });
    const dataActor = dataActorSelector(machineService.getSnapshot());
    return dataActor.ref;
};

export const generateDataForSavingEvent = ({ nextValue, colDef, object }) => {
    let valueToSave = nextValue;
    const expectedValue = nextValue;
    const { field, cellEditorParams = {} } = colDef;
    const { fieldToUpdate = null, propertyToSave = null } = cellEditorParams;
    if (propertyToSave) {
        if (Array.isArray(valueToSave)) {
            valueToSave = nextValue?.map((obj) => obj?.[propertyToSave] ?? obj);
        }
        valueToSave = valueToSave?.[propertyToSave] ?? valueToSave;
    }
    if (!fieldToUpdate) {
        return {
            object,
            field,
            value: valueToSave,
            expectedValue
        };
    }

    const { [field]: _fieldToNotUpdateValue, ...restOfObject } = object;
    const newObjectToUpdate = { ...restOfObject, [fieldToUpdate]: _fieldToNotUpdateValue };
    return {
        object: newObjectToUpdate,
        field: fieldToUpdate,
        value: valueToSave,
        expectedValue
    };
};

const sendUpdateDataEventToDataActor = ({ sender, objectToUpdate, field, value, expectedValue }) => {
    const event = {
        type: "UPDATE_DATA",
        object: { ...objectToUpdate },
        fieldBeingUpdated: { [field]: value }
    };
    if (expectedValue) {
        event.expectedUpdateResult = { [field]: expectedValue };
    }
    sender(event);
};

export const relateBobj = ({
    bobj,
    targetBobj,
    targetBobjRelationshipAttribute,
    targetBobjRelationshipType,
    sendToTargetBobjEntityDataActor
}) => {
    const targetBobjCurrentRelatedValue = targetBobj?.[targetBobjRelationshipAttribute];
    let nextTargetBobjRelatedValue;
    switch (targetBobjRelationshipType) {
        case "many":
            let idListOfCurrentRelatedValue;
            if (Array.isArray(targetBobjCurrentRelatedValue)) {
                idListOfCurrentRelatedValue = targetBobjCurrentRelatedValue
                    .map((relatedBobjOrId) => relatedBobjOrId?.id ?? relatedBobjOrId);
            } else {
                idListOfCurrentRelatedValue = [];
            }
            nextTargetBobjRelatedValue = [...idListOfCurrentRelatedValue, bobj.id].filter((val) => val !== null);
            const uniqueNextTargetBobjRelatedValue = Array.from(new Set(nextTargetBobjRelatedValue));
            if (!isEqual(idListOfCurrentRelatedValue, uniqueNextTargetBobjRelatedValue)) {
                const nextTargetBobj = {
                    ...targetBobj,
                    [targetBobjRelationshipAttribute]: uniqueNextTargetBobjRelatedValue
                };
                sendUpdateDataEventToDataActor({
                    sender: sendToTargetBobjEntityDataActor,
                    objectToUpdate: nextTargetBobj,
                    field: targetBobjRelationshipAttribute,
                    value: uniqueNextTargetBobjRelatedValue
                });
            }
            break;
        case "one":
            nextTargetBobjRelatedValue = bobj.id;
            if (!isEqual(targetBobjCurrentRelatedValue, nextTargetBobjRelatedValue)) {
                const nextTargetBobj = {
                    ...targetBobj,
                    [targetBobjRelationshipAttribute]: nextTargetBobjRelatedValue
                };
                sendUpdateDataEventToDataActor({
                    sender: sendToTargetBobjEntityDataActor,
                    objectToUpdate: nextTargetBobj,
                    field: targetBobjRelationshipAttribute,
                    value: nextTargetBobjRelatedValue
                });
            }
            break;
        default:
            break;
    }
};

export const unrelateBobj = ({
    bobj,
    targetBobj,
    targetBobjRelationshipAttribute,
    targetBobjRelationshipType,
    sendToTargetBobjEntityDataActor
}) => {
    const targetBobjCurrentRelatedValue = targetBobj?.[targetBobjRelationshipAttribute];
    let nextTargetBobjRelatedValue;
    switch (targetBobjRelationshipType) {
        case "many":
            let idListOfCurrentRelatedValue;
            if (Array.isArray(targetBobjCurrentRelatedValue)) {
                idListOfCurrentRelatedValue = targetBobjCurrentRelatedValue
                    .map((relatedBobjOrId) => relatedBobjOrId?.id ?? relatedBobjOrId);
            } else {
                idListOfCurrentRelatedValue = [];
            }
            nextTargetBobjRelatedValue = idListOfCurrentRelatedValue.filter((bobjId) => bobjId !== bobj.id);
            const uniqueNextTargetBobjRelatedValue = Array.from(new Set(nextTargetBobjRelatedValue));

            if (!isEqual(idListOfCurrentRelatedValue, uniqueNextTargetBobjRelatedValue)) {
                const nextTargetBobj = {
                    ...targetBobj,
                    [targetBobjRelationshipAttribute]: uniqueNextTargetBobjRelatedValue
                };
                sendUpdateDataEventToDataActor({
                    sender: sendToTargetBobjEntityDataActor,
                    objectToUpdate: nextTargetBobj,
                    field: targetBobjRelationshipAttribute,
                    value: uniqueNextTargetBobjRelatedValue
                });
            }
            break;
        case "one":
            if (targetBobjCurrentRelatedValue === bobj.id) {
                nextTargetBobjRelatedValue = null;
            } else {
                nextTargetBobjRelatedValue = targetBobjCurrentRelatedValue;
            }
            if (!isEqual(targetBobjCurrentRelatedValue, nextTargetBobjRelatedValue)) {
                const nextTargetBobj = {
                    ...targetBobj,
                    [targetBobjRelationshipAttribute]: nextTargetBobjRelatedValue
                };
                sendUpdateDataEventToDataActor({
                    sender: sendToTargetBobjEntityDataActor,
                    objectToUpdate: nextTargetBobj,
                    field: targetBobjRelationshipAttribute,
                    value: nextTargetBobjRelatedValue
                });
            }
            break;
        default:
            break;
    }
};

export const getBobjFromListUsingIdOrBobj = ({ bobjList = [], IdOrBobj }) => {
    if (typeof IdOrBobj === "string") {
        return bobjList.find((bob) => bob.id === IdOrBobj);
    }
    return IdOrBobj;
};

/**
 * Add values to the mapped attribute of the bobj
 * Useful to update the mapped attribute in bulk.
 *
 * For example: Add 2 products to a microsite
 * bobj - The microsite
 * mappedAttribute - the product attribute of the microsite bobj
 * valuesToAdd - The list of products to add to bobj[mappedAttribute]
 *
 * @param dataMachineService
 * @param bobj - Object with the mapped attribute
 * @param mappedAttribute - Mapped attribute name
 * @param valuesToAdd - List of values to add to bobj[mappedAttribute]
 */
export const addToListOfMappedBobjAttribute = ({
    dataMachineService,
    bobj,
    mappedAttribute,
    valuesToAdd
}) => {
    const { entity } = bobj;
    const { send: sender } = getActorRefFromMachineService({ machineService: dataMachineService, entity });
    const currentValue = bobj?.[mappedAttribute]?.map((obj) => isObject(obj) ? obj.id : obj);
    let nextValue;
    if (Array.isArray(currentValue)) {
        nextValue = [...currentValue, ...valuesToAdd];
    } else {
        nextValue = [...valuesToAdd];
    }
    nextValue = Array.from(new Set(nextValue));
    sendUpdateDataEventToDataActor({
        sender: sender,
        objectToUpdate: bobj,
        field: mappedAttribute,
        value: nextValue
    });
};

export const removeFromListOfMappedBobjAttribute = ({
    dataMachineService,
    bobj,
    mappedAttribute,
    valuesToRemove
}) => {
    const { entity } = bobj;
    const { send: sender } = getActorRefFromMachineService({ machineService: dataMachineService, entity });
    const currentValue = bobj?.[mappedAttribute].map((obj) => isObject(obj) ? obj.id : obj);
    const nextValue = currentValue.filter((val) => !valuesToRemove.includes(val));
    sendUpdateDataEventToDataActor({
        sender: sender,
        objectToUpdate: bobj,
        field: mappedAttribute,
        value: nextValue
    });
};

export const updateMappedBobj = ({
    bobj,
    dataMachineService,
    relationshipConfig,
    nextValue,
    currentValue
}) => {
    const relatedBusinessObjects = getActorDataFromMachineService({ machineService: dataMachineService, entity: relationshipConfig?.relation });
    const relatedEntityDataActor = getActorRefFromMachineService({ machineService: dataMachineService, entity: relationshipConfig?.relation });
    const send = relatedEntityDataActor.send;
    const attributeMappedTo = relationshipConfig.mappedTo.split(".")[1];
    switch (relationshipConfig.relationshipType) {
        case "many":
            const valueBeingPersisted = Array.isArray(nextValue) ? nextValue : [];
            const valuesToUnrelate = currentValue && currentValue?.filter((previouslyRelatedValue) => {
                if (typeof previouslyRelatedValue === "string") {
                    return !valueBeingPersisted.includes(previouslyRelatedValue);
                }
                return !valueBeingPersisted.find((valToPersist) => valToPersist?.id === previouslyRelatedValue?.id);
            });

            valueBeingPersisted.map((IdOrBobj) => getBobjFromListUsingIdOrBobj({ bobjList: relatedBusinessObjects, IdOrBobj }))
                .forEach((targetBobj) => {
                    relateBobj({
                        bobj,
                        targetBobj,
                        targetBobjRelationshipAttribute: attributeMappedTo,
                        targetBobjRelationshipType: relationshipConfig.mappedToRelationshipType,
                        sendToTargetBobjEntityDataActor: send
                    });
                });
            valuesToUnrelate?.map((IdOrBobj) => getBobjFromListUsingIdOrBobj({ bobjList: relatedBusinessObjects, IdOrBobj }))
                .forEach((targetBobj) => {
                    unrelateBobj({
                        bobj,
                        targetBobj,
                        targetBobjRelationshipAttribute: attributeMappedTo,
                        targetBobjRelationshipType: relationshipConfig.mappedToRelationshipType,
                        sendToTargetBobjEntityDataActor: send
                    });
                });
            break;
        case "one": // The relationship type is one, so make the change to the related entity
            if (nextValue !== null) {
                const targetBobj = getBobjFromListUsingIdOrBobj({ bobjList: relatedBusinessObjects, IdOrBobj: nextValue });
                relateBobj({
                    bobj,
                    targetBobj,
                    targetBobjRelationshipAttribute: attributeMappedTo,
                    targetBobjRelationshipType: relationshipConfig.mappedToRelationshipType,
                    sendToTargetBobjEntityDataActor: send
                });
            }
            if (currentValue?.id !== nextValue) {
                unrelateBobj({
                    bobj,
                    targetBobj: currentValue,
                    targetBobjRelationshipAttribute: attributeMappedTo,
                    targetBobjRelationshipType: relationshipConfig.mappedToRelationshipType,
                    sendToTargetBobjEntityDataActor: send
                });
            }
            break;
        default:
            break;
    }
};

/**
 * Given a bobj to create
 * Update any mapped properties with either the set value or default value
 */
export const createMappedBobj = ({
    bobj,
    mappedAttributeList = [],
    dataMachineService
}) => {
    mappedAttributeList.forEach((mappedAttribute) => {
        const { field: mappedAttributeField, cellEditorParams = {} } = mappedAttribute;
        const { relationshipConfig } = cellEditorParams;
        const bobjToCreateMappedAttributeValue = bobj?.[mappedAttributeField] ?? null;
        updateMappedBobj({
            bobj,
            dataMachineService,
            relationshipConfig,
            nextValue: bobjToCreateMappedAttributeValue
        });
    });
};
