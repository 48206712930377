import request from './request';
import { baseConfig } from '@app/config/baseConfig';

const api = `${baseConfig.GATEWAY_API_BASE_PROTOCOL}//api.${baseConfig.GATEWAY_API_BASE}`;
export const SequencesService = {
    data: {
        generatedIdService: async ({ attribute }) => {
            const response = await request({
                method: "GET",
                url: `${api}/sequences/${attribute.schemaId}`
            });
            if (response.data.status === "OK") {
                return response.data.sequence_number;
            }
            throw new Error("Sweft Error 2001: An error occurred while calling Sequences API");
        }
    }
};
