import request from './request';
import { v4 as uuidv4 } from 'uuid';
import { baseConfig } from '@app/config/baseConfig';

const ns = '24feb7d3-52a4-4f39-823a-42674acac649';

const commandsApi = `${baseConfig.GATEWAY_API_BASE_PROTOCOL}//api.${baseConfig.GATEWAY_API_BASE}/commands`;
const alertsApi = `${baseConfig.GATEWAY_API_BASE_PROTOCOL}//api.${baseConfig.GATEWAY_API_BASE}/alerts`;

const AlertService = {
    /**
     * Check status of the alerts service
     * Gives a 401 when no session is available
     * @returns {Promise<unknown>}
     */
    status: async () => {
        return request({
            method: "GET",
            url: `${alertsApi}/status`
        });
    },
    saveAlert: async (alert) => {
        const entity = alert.entity;
        const alertId = alert?.alertId;
        if (!alertId) {
            alert.alertId = uuidv4();
        }
        if (alertId === 'undefined') {
            throw new Error('Alert ID is required to create or update an alert');
        }
        if (!entity || entity === 'undefined') {
            throw new Error('Entity is required to create an alert');
        }
        return request({
            method: "PUT",
            url: `${commandsApi}/alert/${entity}/${alertId}`, // Note this uses the commands API
            data: alert
        });
    },
    getAlert: async (alertId) => { // single alert
        const response = await request({
            method: "GET",
            url: `${alertsApi}/${alertId}`
        });

        return response.data;
    },
    getAlerts: async () => {
        const response = await request({
            method: "GET",
            url: `${alertsApi}`
        });
        return response.data.data;
    },
    removeAlert: async (alert) => {
        if (!alert || !alert.alertId || !alert.entity) {
            throw new Error('Alert ID and entity are required to remove an alert');
        }
        const response = await request({
            method: "DELETE",
            url: `${commandsApi}/alert/${alert?.entity}/${alert?.alertId}`,
        });

        return response.data;
    },
    getEntityAlerts: async (entityType) => {
        const response = await request({
            method: "GET",
            url: `${alertsApi}/list/${entityType}`
        });

        return response.data;
    },
};

export default AlertService;
