import { useCallback, useMemo } from "react";
import { useData } from "@app/data/context";
import { generateEntitySavedQueryCountsDataActorSelector } from "@app/data/machine/utils";
import { useActor, useInterpret, useSelector } from "@xstate/react";
import { useFeature } from "flagged";
import { applyAdvancedFilters } from "@app/grid/advancedFilters/advancedFilters";
import { useSpecialty } from "@app/specialty/hooks/useSpecialty";
import { findWorkspaceSpecialty } from "@app/specialty/utils/findWorkspaceSpecialty";
import { generateEntityDataActorMachine } from "@app/data/machine/actors/entityDataActorMachine";
import { evaluatedDataSelector } from "@app/data/machine/selectors";
import { isEqual } from "lodash";

const useLegacyAlertStatus = ({ alert }) => {
    const { entity, alertWorkspaceObj, projectionAttributeList } = alert;
    const { specialty } = useSpecialty();

    const alertDataActorMachine = useMemo(() => {
        return generateEntityDataActorMachine({ entity, type: `alert_${alert.configurationId}`, projectionAttributeList, noParent: true });
    }, [entity, alert.configurationId]);

    const service = useInterpret(alertDataActorMachine, {
        devTools: true,
    });

    const [alertDataActorState, dataActorSend] = useActor(service);

    const alertEntityData = useSelector(service, evaluatedDataSelector, isEqual);

    const matchingObjectList = useMemo(() => {
        let objListForAlert = applyAdvancedFilters({ logic: alert?.savedQueryObject, rows: alertEntityData });
        if (specialty.isEnabled) {
            const workspaceSpecialty = findWorkspaceSpecialty({ specialty, workspace: alertWorkspaceObj });
            if (workspaceSpecialty) {
                objListForAlert = applyAdvancedFilters({ logic: workspaceSpecialty?.savedQueryObject, rows: objListForAlert });
            }
        }
        return objListForAlert;
    }, [alert?.savedQueryObject, alertEntityData, alertWorkspaceObj, specialty]);

    const isLoading = !alertDataActorState?.matches("loader.idle");
    return {
        isLoading,
        savedQueryCount: matchingObjectList.length
    };
};

const useAlertSavedQueryStatus = ({ alert }) => {
    const { entity, savedQueryId, additionalJsonLogicQueryObject } = alert;
    const { dataMachineService } = useData();
    const entitySavedQueryCountsActorSelector = useCallback(() => generateEntitySavedQueryCountsDataActorSelector({ entity, additionalJsonLogicQueryObject }), [entity, additionalJsonLogicQueryObject]);
    const entitySavedQueryCountsActorObj = useSelector(dataMachineService, entitySavedQueryCountsActorSelector());
    const [dataActorState] = useActor(entitySavedQueryCountsActorObj.ref);
    const isLoading = !dataActorState.matches("waitingBeforeNextFetch");
    const { savedQueryCountsByIdMap } = dataActorState.context;
    const savedQueryCount = savedQueryCountsByIdMap?.[savedQueryId];
    return {
        isLoading,
        savedQueryCount
    };
};

export const useAlertStatus = ({ alert }) => {
    const shouldUseSavedQueryAlertsModule = useFeature('savedQueryAlerts');
    if (shouldUseSavedQueryAlertsModule) {
        return useAlertSavedQueryStatus({ alert });
    }
    return useLegacyAlertStatus({ alert });
};
