import AlertService from "@app/services/alertService";
import AlertConfigService from "@app/services/alertConfigService";
import { generateOptimisticDataMachine } from "@app/data/machine/actors/optimisticDataActorMachine";
import { isFlagOn, flagNames } from "@app/common/featureFlags";


// Swap services depending on the feature flag
const standaloneAlertsApi = isFlagOn(flagNames.alertStandaloneReadApiEnabled);
const primaryService = standaloneAlertsApi ? AlertService : AlertConfigService;
const keyProp = standaloneAlertsApi ? "alertId" : "configurationId";

const alertsConfigDataActor = {
    type: "alertsConfig",
    keyProperty: keyProp,
    createMachineOptions: {
        services: {
            createService: async (context) => {
                const { object } = context;
                return new Promise((resolve) => {
                    primaryService.saveAlert(object).then(() => {
                        resolve({ newObject: object });
                    });
                });
            }
        }
    },
    updateMachineOptions: {
        services: {
            updateService: async (context) => {
                const { object } = context;
                return new Promise((resolve) => {
                    primaryService.saveAlert(object).then(() => {
                        resolve({ newObject: object });
                    });
                });
            }
        }
    },
    deleteMachineOptions: {
        services: {
            deleteService: async (context) => {
                const { object } = context;
                return new Promise((resolve) => {
                    primaryService.removeAlert(object).then(() => {
                        resolve({ deletedObject: object });
                    });
                });
            }
        }
    },
    services: {
        loadService: async () => {
            return new Promise((resolve) => {
                primaryService.getAlerts().then((newAlerts) => {
                    resolve({ loadedData: newAlerts });
                });
            });
        },
        loadDataFilteredForTransientData: async () => {
            return new Promise((resolve) => {
                primaryService.getAlerts().then((newAlerts) => {
                    resolve({ loadedData: newAlerts });
                });
            });
        }
    },
    transientConfig: {
        transientProperty: "modifiedTimestamp",
        transientValueOnCreate: () => new Date().getTime(),
        transientValueOnUpdate: () => new Date().getTime()
    }
};

export const alertsConfigDataActorMachine = generateOptimisticDataMachine(alertsConfigDataActor);
