import { createMachine } from "xstate";

export const generateDeleteDataMachine = ({ context, deleteMachineOptions }) => {
    return createMachine({
        id: 'deleteData',
        context,
        initial: "callingDeleteService",
        states: {
            callingDeleteService: {
                invoke: {
                    src: "deleteService",
                    onDone: {
                        target: "done"
                    }
                }
            },
            done: {
                type: "final"
            }
        }
    },
    {
        ...deleteMachineOptions
    });
};
