import { createMachine, sendParent } from "xstate";

export const generateLoadDataMachine = ({ context: machineContext, loadMachineOptions }) => {
    console.log({ loadMachineOptions });
    return createMachine({
        id: `loadData`,
        context: machineContext,
        initial: "loadingData",
        states: {
            loadingData: {
                invoke: {
                    src: "loadService",
                    onDone: {
                        target: "dataLoaded",
                        actions: sendParent((context, event) => {
                            const loadedData = event?.data?.loadedData ?? [];
                            return {
                                type: `DATA_LOADED`,
                                loadedData
                            };
                        })
                    }
                }
            },
            dataLoaded: {
                type: "final"
            }
        }
    }, {
        ...loadMachineOptions
    });
};
