/**
 * If an entity data actor is found in the provided context for the provided entity then the actor is returned.
 * Otherwise, returns undefined
 * @param {SweftDataMachineContext} context
 * @param {string} entity
 * @returns {SweftDataMachineEntityDataActorMachine|undefined}
 */
import { isEqual } from "lodash";

export const findDataActorObjForEntityFromContext = ({ context, entity }) =>
    context.entityDataActorList.find((dataActorObj) => dataActorObj.entity === entity);

export const findDataActorObjForConfigFromContext = ({ context, config }) =>
    context.configDataActorList.find((dataActorObj) => dataActorObj.config === config);

export const findDataActorObjForEntitySavedQueryCountsFromContext = ({ context, entity }) =>
    context.entitySavedQueryCountsDataActorList.find((dataActorObj) => dataActorObj.entity === entity && !dataActorObj.additionalJsonLogicQueryObject);

export const findDataActorObjForEntitySavedQueryCountsWithAdditionalJsonLogicObjectFromContext = ({ context, entity, additionalJsonLogicQueryObject }) => {
    return context.entitySavedQueryCountsDataActorList.find((dataActorObj) => dataActorObj.entity === entity && isEqual(dataActorObj.additionalJsonLogicQueryObject, additionalJsonLogicQueryObject));
};

export const findDataActorObjForEntitySavedQueryFromContext = ({ context, entity, savedQuery }) => {
    return context.entitySavedQueryDataActorList.find((dataActorObj) => dataActorObj.entity === entity && dataActorObj?.savedQuery?.id === savedQuery.id);
};

export const generateEntitySavedQueryCountsDataActorSelector = ({ entity, additionalJsonLogicQueryObject }) => {
    return function (state) {
        let dataActorObj;

        if (additionalJsonLogicQueryObject) {
            dataActorObj = findDataActorObjForEntitySavedQueryCountsWithAdditionalJsonLogicObjectFromContext({ context: state.context, entity, additionalJsonLogicQueryObject });
        } else if (entity) {
            dataActorObj = findDataActorObjForEntitySavedQueryCountsFromContext({ context: state.context, entity });
        }

        if (!!dataActorObj === true) {
            return dataActorObj;
        }

        return state.context.unknownDataActor;
    };
};
