import request from './request';
import { v4 as uuidV4 } from 'uuid';
import { baseConfig } from '@app/config/baseConfig';

const copywritingApi = `${baseConfig.GATEWAY_API_BASE_PROTOCOL}//api.${baseConfig.GATEWAY_API_BASE}/copywriting`;

const CopywritingService = {
    /**
     * Check status of the copywriting service and whether client has a session or not
     * Gives a 401 when no session is available
     * @returns {Promise<unknown>}
     */
    status: async () => {
        return request({
            method: "GET",
            url: `${copywritingApi}/status`
        });
    },

    generateUuid: () => {
        return uuidV4();
    },
    getTemplateList: async () => {
        const response = await request({
            method: "GET",
            url: `${copywritingApi}/templates`
        });
        return response.data.data;
    },
    saveTemplate: async (config) => {
        if (!config?.domainId) {
            config.domainId = uuidV4();
        }
        const response = await request({
            method: "PUT",
            url: `${copywritingApi}/templates/${config.domainId}`,
            data: config
        });

        return response.data;
    },
    removeTemplate: async (config) => {
        const response = await request({
            method: "DELETE",
            url: `${copywritingApi}/templates/${config.domainId}`
        });
        return response.data;
    },
    getDocumentList: async () => {
        const response = await request({
            method: "GET",
            url: `${copywritingApi}/documents`
        });
        return response.data.data;
    },
    getDocumentListByRelatedEntity: async (entity, entityId = "") => {
        const response = await request({
            method: "GET",
            url: `${copywritingApi}/documents/relatedEntity/${entity}/${entityId}`
        });
        return response.data.data;
    },
    saveDocument: async (config) => {
        if (!config?.domainId) {
            config.domainId = uuidV4();
        }
        const response = await request({
            method: "PUT",
            url: `${copywritingApi}/documents/${config.domainId}`,
            data: config
        });

        return response.data;
    },
};

export default CopywritingService;
