import { createMachine, assign } from "xstate";
/**
 * A machine for calling a createService and passing in an object to update via the context
 *
 * @param context - The object that will be created
 * @param createMachineOptions - Machine options (actions, services, guards)
 * @returns {StateMachine}
 */
export const generateCreateDataMachine = ({ context: machineContext, createMachineOptions }) => {
    return createMachine({
        id: `createData`,
        context: machineContext,
        initial: "checkIfGenIdIsNeeded",
        states: {
            checkIfGenIdIsNeeded: {
                always: [
                    {
                        target: "gettingGeneratedIdValuesForObject",
                        cond: "objectNeedsGeneratedIdValues"
                    },
                    {
                        target: "callingCreateService"
                    }
                ]
            },
            gettingGeneratedIdValuesForObject: {
                invoke: {
                    src: "generatedIdService",
                    onDone: {
                        actions: "setObjectInContext",
                        target: 'callingCreateService',
                    },
                },
            },
            callingCreateService: {
                invoke: {
                    src: "createService",
                    onDone: {
                        target: 'done',
                    },
                },
            },
            done: {
                type: "final"
            }
        }
    }, {
        guards: {
            objectNeedsGeneratedIdValues: (context) => context?.gidAttributesList?.length > 0
        },
        actions: {
            setObjectInContext: assign({
                object: (context, event) => {
                    return event?.data?.newObject || context.object;
                }
            })
        },
        ...createMachineOptions
    });
};
