import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import './stylesheets/version-notification.less';

const VersionNotification = (props) => {
    const [shouldReload, setShouldReload] = useState(false);
    const [currentVersion, setCurrentVersion] = useState(null);

    // custom hook suggested by Dan Abramov
    // https://overreacted.io/making-setinterval-declarative-with-react-hooks/
    function useInterval(callback, delay) {
        const savedCallback = useRef();

        // Remember the latest callback.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        useEffect(() => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                const id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }

    const reload = () => {
        window.location.reload(true);
    };

    const checkVersion = () => {
        return axios(props.url).then((response) => {
            setCurrentVersion(props.buildVersion);

            const version = response.data;
            if (!currentVersion) {
                setCurrentVersion(version);
            } else if (version !== currentVersion) {
                setShouldReload(true);
            }

            return response.data;
        });
    };

    useEffect(() => {
        checkVersion();
    }, []);

    useInterval(checkVersion, props.frequency);

    return shouldReload ? (
        <div className="version-notification-container">
            A new version of Sweft is available.
            {" "}
            <button className="button" onClick={reload}>
                Update Now
            </button>
        </div>
    ) : <></>;
};

VersionNotification.propTypes = {
    buildVersion: PropTypes.string,
    frequency: PropTypes.number,
    url: PropTypes.string,
};

VersionNotification.defaultProps = {
    buildVersion: "",
    frequency: (1000 * 60 * 3),
    url: '/VERSION',
};

export default VersionNotification;
