import { evaluateBobj } from "@app/data/utils";

export const _getTransientObject = ({ event, transientConfig, transientValueGetter }) => {
    const transientObject = {
        ...event.object,
        ...(event?.expectedUpdateResult ?? {}),
    };
    transientObject[transientConfig.transientProperty] = transientValueGetter(transientObject);
    if (transientConfig.evaluateTransientObject === false) {
        return transientObject;
    }
    return evaluateBobj({ bobj: transientObject });
};
