/**
 * ------------------------------------------------------------
 * Selector:
 * A function that takes in an actor's "current state" (snapshot)
 * as an argument and returns the desired selected value.
 * Selectors are used with the useSelector hook.
 * ------------------------------------------------------------
 */

import {get, set} from "lodash";
import {getUniqueObjList} from "@app/common/utils";

const combineDataAndTransientData = ({data, transientData, keyProperty, relatedTransientObjMap}) => {
    const newData = data;
    transientData.forEach(({object}) => {
        const indexOnData = data.findIndex((bobj) => bobj[keyProperty] === object[keyProperty]);
        if (indexOnData > -1) {
            data[indexOnData] = object;
        } else {
            newData.unshift(object);
        }
    });
    let newDataWithTransientRelatedObj = newData;
    const propertyList = Object.keys(relatedTransientObjMap).filter((property) => relatedTransientObjMap[property]?.length !== 0);
    if (propertyList?.length !== 0) {
        newDataWithTransientRelatedObj = newDataWithTransientRelatedObj.map((obj) => {
            const objWithTransientRelatedData = obj;
            propertyList.forEach((property) => {
                const valAtProperty = get(objWithTransientRelatedData, property);
                let nextValAtProperty = valAtProperty;
                if (valAtProperty) {
                    if (Array.isArray(valAtProperty)) {
                        nextValAtProperty = valAtProperty.map((relatedObj) => {
                            const relatedTransientObjExists = relatedTransientObjMap[property].find(transientRelatedObj => transientRelatedObj[keyProperty] === relatedObj[keyProperty]);
                            if (relatedTransientObjExists) {
                                return relatedTransientObjExists;
                            }
                            return relatedObj;
                        });
                    } else {
                        const relatedTransientObjExists = relatedTransientObjMap[property].find(transientRelatedObj => transientRelatedObj[keyProperty] === valAtProperty[keyProperty]);
                        if (relatedTransientObjExists) {
                            nextValAtProperty = relatedTransientObjExists;
                        }
                    }

                    set(objWithTransientRelatedData, property, nextValAtProperty);
                }
            });
            return objWithTransientRelatedData;
        });
    }

    return getUniqueObjList({objectList: newDataWithTransientRelatedObj, keyProperty});
};

/**
 * Evaluates all the formula fields in each bobj
 * If the bobj has already been evaluated, and the formula attributes are removed,
 * revaluate the formula from the formulaAttributeMapByAttributeId
 */
export const evaluatedDataSelector = (state) => {
    if (state.value.loader !== "idle") {
        return [];
    }
    const {keyProperty} = state?.context ?? "id";
    let data = [];
    try {
        data = state?.context?.evaluatedData?.map((obj) => obj.data || obj);
    } catch (e) {
        const machineId = state?.machine?.id;
        console.warn(`Error in mapping the loaded data from machine ${machineId}`);
    }

    const transientData = state?.context?.transientData || [];

    const {relatedEntityPropertyMap, relatedTransientObjMap} = state?.context || {};
    return combineDataAndTransientData({data, transientData, keyProperty, relatedEntityPropertyMap, relatedTransientObjMap});
};
