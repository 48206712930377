import jmespath from "jmespath";
import jsonata from "jsonata";
import { find, get } from "lodash";
import { Buffer } from "buffer";
import { notification } from "antd";

export const agGridKey = () => {
    return 'Q29tcGFueU5hbWU9U3dlZnQgTExDLExpY2Vuc2VkQXBwbGljYXRpb249U3dlZnQsTGljZW5zZVR5cGU9U2luZ2xlQXBwbGljYXRpb24sTGljZW5zZWRDb25jdXJyZW50RGV2ZWxvcGVyQ291bnQ9MSxMaWNlbnNlZFByb2R1Y3Rpb25JbnN0YW5jZXNDb3VudD0wLEFzc2V0UmVmZXJlbmNlPUFHLTAzMDk3MyxTdXBwb3J0U2VydmljZXNFbmQ9MTNfU2VwdGVtYmVyXzIwMjNfW3YyXV9NVFk1TkRVMU9UWXdNREF3TUE9PTA2ZmExZDU1YzQ1ZTZiMzI2Y2YzMWNiNTRlMzdkZGU5Cg==';
};

export const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

/** @param {string} displayName */
export const generateNameFromDisplayName = (displayName) => displayName?.trim().toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");

export const getTreeFromFlatOptions = ({ flatOptions, propertyToDisplay, onlyEnableLowestOptions }) => {
    const tree = [];
    flatOptions.sort((optionA, optionB) => (optionA.hierarchy > optionB.hierarchy) ? 1 : -1);

    flatOptions.forEach((flatOption) => {
        const optionPathArr = flatOption?.hierarchy?.split("/").slice(0, -1);
        let currentLevel = tree;
        optionPathArr?.forEach((optionPathPart) => {
            const existingPath = currentLevel.find((treeObj) => treeObj.partName === optionPathPart);
            if (existingPath) {
                currentLevel = existingPath.children;
                if (onlyEnableLowestOptions) {
                    existingPath.disabled = true;
                }
            } else {
                const newPart = {
                    title: flatOption[propertyToDisplay],
                    partName: optionPathPart,
                    value: flatOption.hierarchy,
                    children: [],
                    hierarchy: flatOption.hierarchy,
                    key: flatOption.hierarchy
                };
                currentLevel.push(newPart);
                currentLevel = newPart.children;
            }
        });
    });

    return tree;
};


export const getDefaultExpandedKeys = ({ treeData, defaultExpandedLevel = 0 }) => {
    const defaultExpandedKeys = [];
    let currentExpandedLevel = defaultExpandedLevel;
    let currentTreeData = treeData;
    while (currentExpandedLevel > 0) {
        const currentLevelKeys = currentTreeData.map((treeNode) => treeNode.key);
        defaultExpandedKeys.push(...currentLevelKeys);
        currentTreeData = [...currentTreeData.map((treeNode) => treeNode.children ?? [])].flat();
        currentExpandedLevel = currentExpandedLevel - 1;
    }
    return defaultExpandedKeys;
};


export const isObject = (val) => typeof val === 'object' && !Array.isArray(val) && val !== null;

export const formulaProcessors = {
    jmespath(obj, formulaValue) {
        try {
            return jmespath.search(obj, formulaValue);
        } catch (e) {
            return null;
        }
    },
    jsonata(obj, expressionStr) {
        try {
            const expression = jsonata(expressionStr);
            return expression.evaluate(obj);
        } catch (e) {
            return null;
        }
    }
};

export const getRelatedEntityListFromAttributeList = (attributeList) => {
    return [
        ...new Set(attributeList?.filter((attribute) => {
            if (attribute.baseType === "Relationship" && attribute.relation) {
                return true;
            }
            return !!((attribute.format === "Dropdown" || attribute.format === "NestedDropdown") && attribute.specifications?.editorParams?.dropdownId);
        }).map((attribute) => {
            if (attribute.baseType === "Relationship") {
                return attribute.relation;
            }
            if (attribute.format === "Dropdown" || attribute.format === "NestedDropdown") {
                const { dropdownId = null } = attribute.specifications.editorParams;
                return generateNameFromDisplayName(dropdownId);
            }
            return null;
        }))
    ];
};

/**
 * Comparator function to use when sorting objects by their createdDate property
 * @param workspaceData [Array]
 * @returns {Array}
 */
export const compareByCreatedDate = (workspaceData) => {
    return workspaceData?.sort((objA, objB) => {
        const dateA = new Date(objA.createdDate).getTime();
        const dateB = new Date(objB.createdDate).getTime();

        if (!objA.createdDate) {
            return 0;
        }
        if (!objB.createdDate) {
            return -1;
        }

        return dateB - dateA;
    });
};

export const getCurrentSearchParams = () => (new URL(document.location)).searchParams;

export const sortArray = (array, key) => (array.sort((valueA, valueB) => valueA[key]?.localeCompare(valueB[key])));

export const findInList = (list, predicate) => find(list, predicate) ?? null;

export const base64Encode = (stringToEncode) => Buffer.from(stringToEncode).toString('base64');

export const base64Decode = (stringToDecode) => Buffer.from(stringToDecode, 'base64').toString('ascii');

/**
 * Use AntD notification to show error messages
 * @param {string} message
 * @param {string} description
 * @param {'error'|'success'|'info'|'warning'} type
 */
export const openNotificationWithIcon = (type, message, description) => {
    const finalType = ['error', 'success', 'info', 'warning'].includes(type) ? type : 'error';
    notification[finalType]({
        message,
        description,
    });
};

/**
 * Remove objects from the list that have duplicate values for the keyProperty.
 * The last object that matches the value is kept.
 * @param objectList
 * @param keyProperty
 * @returns {[]}
 */
export const getUniqueObjList = ({ objectList = [], keyProperty = null } = {}) => [...new Map(objectList?.map(object =>
    [object?.[keyProperty], object]) ?? []).values()];

/**
 * Sort object keys using default Array sort method
 * @param obj
 * @returns {{}}
 */
export const sortObjKeys = ({ obj = {} } = {}) => Object.keys(obj).sort().reduce((sortedObj, nextKey) => ({ ...sortedObj, [nextKey]: obj[nextKey] }), {});

/**
 * Returns tghe value at path of object
 * @param obj
 * @param path
 * @returns {any}
 */
export const getObjectPathValue = ({ obj = {}, path = null } = {}) => get(obj, path);
