// @ts-check
import request from './request';
import { baseConfig } from '@app/config/baseConfig';
import { base64Encode } from "@app/common/utils";
// @ts-ignore
const api = `${baseConfig.GATEWAY_API_BASE_PROTOCOL}//api.${baseConfig.GATEWAY_API_BASE}`;

export const SavedQueryService = {
    getSavedQueryListByReferenceIdList: async ({ idList = [] }) => {
        return request({
            method: "GET",
            url: `${api}/saved-queries?saved-query-reference-id-list=${idList.toString()}`
        });
    },
    getEntitySavedQueryCounts: async ({ entity }) => {
        const response = await request({
            method: "GET",
            url: `${api}/data/${entity}/saved-query-counts`
        });
        return response?.data;
    },
    getEntitySavedQueryCountsForSavedQueryIdList: async ({ entity, savedQueryIdList, additionalJsonLogicQueryObject }) => {
        let jlQueryParam = "";
        if (additionalJsonLogicQueryObject) {
            jlQueryParam = `&jlQuery=${base64Encode(JSON.stringify(additionalJsonLogicQueryObject))}`;
        }
        const response = await request({
            method: "GET",
            url: `${api}/data/${entity}/saved-query-counts?saved-query-id-list=${savedQueryIdList.join(",")}${jlQueryParam}`
        });
        return response?.data;
    }
};
