export const optimisticDataActorMachineGuardsBuilder = ({ guardsOptions }) => {
    const hasTransientData = (context) => {
        return context.transientData && context.transientData.length > 0;
    };
    const notAtMaxPollerDelayAndHasTransientData = (context) => {
        return (context.currentPollingDelay !== context.maxPollingDelay) && (context.transientData && context.transientData.length > 0);
    };

    const shouldAddObjectToTransientData = (context, event) => {
        const { transientObject } = event;
        if (guardsOptions?.shouldAddObjectToTransientDataCallback && typeof guardsOptions?.shouldAddObjectToTransientDataCallback === 'function') {
            return guardsOptions.shouldAddObjectToTransientDataCallback({ object: transientObject });
        }
        return true;
    };

    const freshDataIsPresent = (context, event) => event?.data?.freshData.length > 0;

    return {
        hasTransientData,
        notAtMaxPollerDelayAndHasTransientData,
        shouldAddObjectToTransientData,
        freshDataIsPresent
    };
};
