/* eslint-disable max-lines */
// @ts-check
import React, { useEffect, useState } from 'react';
import AuthService from '@app/services/authService';
import CopywritingService from '@app/services/copywritingService';
import { Link } from 'react-router-dom';
import { useAuth } from '@app/auth/context/useAuth';
import { SimpleSkeleton } from '@app/auth/login/LoadingArea';
import { AvatarArea } from '../components/avatarArea';
import ConfigurationService from '@app/services/configurationService';
import MessagesService from '@app/services/messagesService';
import AlertService from '@app/services/alertService';
import AlertConfigService from '@app/services/alertConfigService';
import UserService from '@app/services/userService';
import { VanguardService } from '@app/services/vanguardService';
import { CheckCircleFilled, CloseCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';

// simple wrapper to avoid having to call service.data.method only for vanguard service
const vanguardDataWrapper = {
    ...VanguardService.data,
    status: VanguardService.status,
};

/**
 * @type {{name:string, description:string, statusLevel:number, masterService:any, method: string | ((unknown)=>boolean) | ((service: any) => Promise<boolean>), status:'Operational'|'Degraded'|'Outage'|undefined}[]}
 */
export const servicesForStatuses = [
    {
        "name": "AuthService",
        "description": "Authentication Service",
        "statusLevel": -5,
        masterService: AuthService,
        method: 'status',
        status: undefined,
    },
    {
        "name": "UserService",
        "description": "User Service",
        "statusLevel": -5,
        masterService: UserService,
        method: 'status',
        status: undefined,
    },
    {
        "name": "ConfigurationService",
        "description": "Configuration Service",
        "statusLevel": -5,
        masterService: ConfigurationService,
        method: 'status',
        status: undefined,
    },
    {
        "name": "VanguardService",
        "description": "Vanguard Service",
        "statusLevel": -5,
        masterService: vanguardDataWrapper,
        method: 'status',
        status: undefined,
    },
    {
        "name": "VanguardService - Queries",
        "description": "Vanguard Service - Queries",
        "statusLevel": -5,
        masterService: vanguardDataWrapper,
        method: async (service) => {
            try {
                return Promise.resolve(Boolean(await service.getBusinessObjectsOfEntity({ entity: 'product' })));
            } catch (e) {
                try {
                    return Promise.resolve(Boolean(await service.getBusinessObjectsOfEntity({ entity: 'Product' })));
                } catch (e) {
                    console.error(e);
                    return Promise.resolve(false);
                }
            }
        },
        status: undefined,
    },
    {
        "name": "VanguardService - Saved Queries",
        "description": "Vanguard Service - Saved Queries",
        "statusLevel": -5,
        masterService: vanguardDataWrapper,
        method: async (service) => {
            try {
                return Promise.resolve(Boolean(await service.entityAllSavedQueries({ entity: 'product' })));
            } catch (e) {
                try {
                    return Promise.resolve(Boolean(await service.entityAllSavedQueries({ entity: 'Product' })));
                } catch (e) {
                    console.error(e);
                    return Promise.resolve(false);
                }
            }
        },
        status: undefined,
    },
    {
        "name": "CopywritingService",
        "description": "Copywriting Service",
        "statusLevel": -5,
        masterService: CopywritingService,
        method: 'status',
        status: undefined,
    },
    {
        "name": "AlertService",
        "description": "Alert Service",
        "statusLevel": -5,
        masterService: AlertService,
        method: 'status',
        status: undefined,
    },
    {
        "name": "AlertService - List",
        "description": "Alert Service - List",
        "statusLevel": -5,
        masterService: AlertService,
        method: 'getAlerts',
        status: undefined,
    },
    {
        "name": "AlertService - Config",
        "description": "Alert Service - Config",
        "statusLevel": -5,
        masterService: AlertConfigService,
        method: 'getAlerts',
        status: undefined,
    },
    {
        "name": "MessagesService",
        "description": "Messages Service",
        "statusLevel": -5,
        masterService: MessagesService,
        method: 'status',
        status: undefined,
    },

];

/**
 * Show the distinction of whether we are logged in on the status page
 * Since even some of the service statuses require authentication
 * @returns
 */
export const RevealSession = () => {
    const { user } = useAuth();
    return <div className="reveal-session w-15 p-3 flex flex-col justify-center items-center text-center border border-gray-300 rounded-lg bg-slate-50">
        <AvatarArea user={user}/>
        <div className="text-center">{user ? <>Currently <br/>authenticated <br/>as <b>{user?.firstName}</b>.</> : <>Currently <br/>unauthenticated</>}</div>
    </div>;
};

/**
 * Provide data and layout, Constrain the text, Give it a header, Maybe mess with line height
 */
export const StatusWrapper = ({ children = null, overallLoadingOverride = null, className = '' }) => {
    const { user } = useAuth(); // Taking this out breaks the render for now
    return <div className="bg-gray-100 min-h-screen">
        <section className={`status-area px-5 pb-5 mt-5 w-full sm:w-2/3 md:w-2/3 lg:w-1/2 bg-white h-screen  mx-auto ${className ?? ''}`}>
            <div className="self-center justify-center align-center mx-10">
                <header className=" items-center align-center justify-center">
                    <h1 className="font-medium leading-tight text-[48px] text-teal-600  font-black ">Status of Services</h1>
                </header>
                <div className="intro py-4 px-2 my-5 bg-slate-50 rounded-lg">
                    <div className="p-3">
                        <div className="text-teal-800 leading-relaxed font-medium opacity-70 text-4xl">
                            {!user &&
                                <span className="text-warning">These statuses are only viewable to logged in users, so please <Link to="/login">login</Link> to see them.</span>
                            }{
                                user &&
                            <p>👋🏽 Hello, {user.firstName} </p>
                            }
                            <span className="">Welcome to our status page! Here you can find the current status of our internal services.
                                <br/> This page is updated in real-time, so you can stay informed on the status of our services at all times.
                                <br/>If you have any questions or concerns, please feel free to <Link to="/contact" className="text-teal-600 underline">contact us</Link>.</span>

                            <p className="m-2 text-center col-span-2" />Thank you!

                        </div>
                    </div>
                </div>
            </div>
            <div className="px-8">
                {servicesForStatuses.map((service) => (
                    <StatusInfoLoader
                        service={service}
                        loadingO={overallLoadingOverride}
                        className="status-wrapper-info pb-3 pt-3 container"
                        key={service.name}
                    />
                ))}
            </div>
            <div>
                {children}
            </div>
            <footer className="flex justify-evenly py-2 h-2/12">
                <Link to="/">Home</Link>
                <Link to="/logout">Logout</Link>
            </footer>
        </section>
    </div>;
};

export const performanceTiers = {
    'Operational': 5,
    'Degraded Performance': 4,
    'Underperforming': 1,
    'Outage': -5,
};

/**
 * Essentially return performance tiers
 * @param {number} numericStatus
 * @returns {string} Best tier the performance number matched
 */
export const performanceTier = (numericStatus = -5) => {
    // Loop over the performanceTier and get the highest tier that is higher than the numericStatus
    return Object.keys(performanceTiers).find(tier => performanceTiers[tier] <= numericStatus);
};


/**
 * Loader for gathering the api statuses separately
 * Reloads every once in a while to try to get the latest status
 */
export const StatusInfoLoader = ({ service, loadingO = false, minutesBeforeRefresh = 3, ...rest }) => {
    const [finalService, setFinalService] = useState(service);
    const [loading, setLoading] = useState(true);

    // start loading data on mount
    const initialize = async () => {
        try {
            console.info('Checking api for: ', service.name, service.method);
            const healthCheck = (healthD) => {
                // check for operationalStatus or data length or a boolean
                if (healthD?.data?.operationalStatus) {
                    return healthD.data.operationalStatus;
                } else if (healthD?.data?.length) {
                    return 'Operational';
                } else if (healthD?.data) {
                    return 'Operational';
                } else if (healthD instanceof Array && healthD?.length > 0) {
                    return 'Operational';
                } else if (healthD === true) {
                    return 'Operational';
                }
                return 'Outage';
            };
            let healthData;
            if (typeof service.method === 'function') { // Allow custom calls if desired
                healthData = await service.method(service.masterService);
            } else {
                healthData = await service.masterService[service.method]();
            }
            console.info('completed api check for: ', service.name, service.method, healthData, healthCheck(healthData));
            setFinalService({ ...service, status: healthCheck(healthData) });
            setLoading(false);
        } catch (e) {
            setFinalService({ ...service, status: 'Outage' });
            setLoading(false);
        }
    };
    const MINUTE_MS = 10000;
    useEffect(() => {
        initialize();
        const interval = setInterval(() => {
            initialize();
        }, MINUTE_MS * minutesBeforeRefresh);

        return () => clearInterval(interval); // clear interval to prevent memory leaks.
    }, []);
    // @ts-ignore
    return <StatusInfo service={finalService} {...rest} loading={loading || loadingO}/>;
};

/**
 * Display services statuses individually
 */
export const StatusInfo = ({ className, children, service, loading, ...rest }) => {
    if (!service) {
        throw new Error('StatusInfo requires a service to check');
    }
    const { name, description, status, statusLevel } = service;
    const LoadingWrapper = ({ children, loadingS }) => {
        if (loadingS) {
            return <div className="inline-block align-bottom">
                <SimpleSkeleton className="status-info-skeleton inline-block"/>
            </div>;
        }
        return children;
    };

    const statusStyles = {
        'Operational': 'bg-green-100 border-green-400 text-green-700',
        'Degraded': 'bg-orange-100 border-orange-400 text-orange-700',
        'Outage': 'bg-red-100 border-red-400 text-red-700',
    };

    const statusTextStyle = {
        'Operational': 'text-green-700',
        'Degraded': 'text-orange-700',
        'Outage': ' text-red-700',
    };

    const statusTier = performanceTier(statusLevel);
    const statusStyle = loading ? '' : statusStyles[status] ?? '';
    const apiUrl = service?.apiUrl;
    return <div className={`status-info-area ${className}`} {...rest}>
        <div className="py-4 border-b">
            <h3 className="text-4xl font-medium tracking-tight  text-gray-500 leading-normal mt-0 mb-2 hidden">
                {description}
                <span className="subtitle">{apiUrl && <Tooltip title={apiUrl}><InfoCircleFilled/></Tooltip>}</span>
            </h3>
            <div className={` hidden status-operationality tier-${statusTier}
                border-black text-gray px-4 py-3 rounded relative
                status-info-text ${statusStyle}`}
            >
                Status:{' '}
                <LoadingWrapper loadingS={loading}>
                    <span>    ✅ {status === 'Operational' ? 'Operational' : 'Outage'}</span>
                </LoadingWrapper>
            </div>
            <div className="flex justify-between">
                <h3 className={`text-4xl font-medium mb-0 flex align-center gap-4 ${statusTextStyle[status]}`}>
                    {status === 'Operational' ? <CheckCircleFilled/> : <CloseCircleFilled/>}{description}
                </h3>
                <span className={`font-medium text-3xl ${statusTextStyle[status]}`}> {status === 'Operational' ? 'Operational' : 'Outage'}</span>
            </div>
            {/*
            // commented out performance as not implemented yet
            <div className={`status-performance tier-${statusTier}`}>
                Performance:{' '}
                <LoadingWrapper loadingS={loading}>
                    {statusLevel?.toString()} <em>(Speed {statusTier})</em>
                </LoadingWrapper>
            </div>
            */}
        </div>
        {children && <div className="status-list-extras">
            {children}
        </div>}
    </div>;
};
