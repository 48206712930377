// @ts-check
import React, {createContext, useContext, useMemo} from "react";
import {useWorkspaces} from "@app/workspace/contexts/useWorkspaces";
import {getAlertsForRole, hydrateAlertObj} from "@app/contexts/useAlerts/utils";
import {useFeature} from 'flagged';
import {SavedQueryAlertsContextProvider} from "@app/contexts/useAlerts/savedQueryAlertsContextProvider";
import {useCurrentUserRole} from "@app/auth/useCurrentUserRole";
import {useConfigDataActor} from "@app/data/config/hooks/useConfigDataActor";

/**
 * @type {React.Context<AlertsContextValue>}
 */
export const AlertsContext = createContext();

/**
 * Custom consumer hook for the Alerts context
 * @returns {AlertsContextValue}
 */
export const useAlerts = () => {
    const context = useContext(AlertsContext);
    if (context === undefined) {
        throw new Error('useAlerts must be used within an AlertsContext.Provider');
    }
    return context;
};

/**
 * Custom provider component for the Alerts context.
 * Used for globally loading alerts into state and processes them for the current user role.
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export const LegacyAlertsWrapper = ({children}) => {
    const {findWorkspaceObjBasedOnId} = useWorkspaces();
    const {currentUserRole} = useCurrentUserRole();
    const {dataActorData: alertList} = useConfigDataActor({config: "alerts"});
    const {dataActorData: viewList} = useConfigDataActor({config: "views"});
    const alerts = useMemo(() => {
        const userAlertList = getAlertsForRole(alertList, currentUserRole);
        return userAlertList.map((alertObj) => {
            const workspaceObj = findWorkspaceObjBasedOnId(alertObj.workspace);
            return {
                ...hydrateAlertObj({alert: alertObj, workspaceObj}),
            };
        });
    }, [alertList, currentUserRole, viewList, findWorkspaceObjBasedOnId]);

    /**
     * @type {AlertsContextValue}
     */
    const contextValue = useMemo(() => ({
        alerts,
    }), [alerts]);

    return (
        <AlertsContext.Provider value={contextValue}>{children}</AlertsContext.Provider>
    );
};

export const AlertsWrapper = ({children}) => {
    const shouldUseSavedQueryAlertsContext = useFeature('savedQueryAlerts');
    if (shouldUseSavedQueryAlertsContext) {
        return <SavedQueryAlertsContextProvider>{children}</SavedQueryAlertsContextProvider>;
    }
    return <LegacyAlertsWrapper>{children}</LegacyAlertsWrapper>;
};
